import React from "react";
import ComponentHeading from "../../../components/Headings/ComponentHeading";
import ComponentContainer from "../../../components/ComponentContainer";
import ProgressBar from "../../../components/ProgressBar";
import { ReactComponent as TeethSvg } from "../../../assets/svg/teeth.svg";
import Button from "../../../components/Button";
import "../../../css/battle-pass.css";
import Slider from "../../../components/Slider";
import SmallComponentHeading from "../../../components/Headings/SmallComponentHeading";
import DinosaurCard from "../../../components/DinosaurCard";
import img1 from "../../../assets/img/dinosaur/Изображение.png";
import { dinosaurSizes, dinosaurTypes } from "../../../utils/consts";
import SubHeading from "../../../components/Headings/SubHeading";

const BattlePass = () => {
  const battlePassRewards = Array(20).fill(
    <DinosaurCard
      hint={"1 LVL"}
      name={"НАИМЕНОВАНИЕ"}
      tier={1}
      type={dinosaurTypes.herbivorous}
      image={img1}
      size={dinosaurSizes.normal}
    />
  );
  return (
    <div className="battle-pass-page page">
      <ComponentContainer>
        <ComponentHeading>СЕЗОННЫЙ ПРОПУСК</ComponentHeading>
        <div className="battle-pass">
          <div className="battle-pass-container">
            <div className="battle-pass-head">
              <div className="battle-pass-season">
                <span className="bold text-outlined font-size-4">
                  СЕЗОН 1-’23
                </span>
              </div>
              <div className="free-points">
                <SubHeading className="gradient-text font-size-1-75">
                  СЕЗОННЫЙ ПРОПУСК
                </SubHeading>
                <div className="gradient-background">
                  <p>
                    <span className="text-transparent">
                      ДО ПОЛУЧЕНИЯ ОПЫТА:{" "}
                    </span>
                    <span className="font-size-1-25">1ч 59м</span>
                  </p>
                </div>
                <p className="text-accent end">Получай 200 ОА каждые 2 часа</p>
              </div>
            </div>
            <div className="battle-pass-missions">
              <div className="missions">
                <div className="missions-container">
                  {Array(3)
                    .fill(0)
                    .map((_, i) => (
                      <div className="mission" key={i}>
                        <ProgressBar value={0} max={25}>
                          <div className="mission-content">
                            <div className="mission-desc">
                              Краткое описание задания
                            </div>
                            <div className="user-progress">0/25</div>
                          </div>
                        </ProgressBar>
                      </div>
                    ))}
                </div>
                <div className="missions-container">
                  {Array(3)
                    .fill(0)
                    .map((_, i) => (
                      <div className="mission" key={i}>
                        <ProgressBar value={0} max={25}>
                          <div className="mission-content">
                            <div className="mission-desc">
                              Краткое описание задания
                            </div>
                            <div className="user-progress">0/25</div>
                          </div>
                        </ProgressBar>
                      </div>
                    ))}
                </div>
                <div className="levels">
                  <div className="level">
                    <div className="level-name">1 уровень</div>
                    <div className="levelt-prize">
                      999 <TeethSvg />
                    </div>
                  </div>
                  <div className="level">
                    <div className="level-name">5 уровней</div>
                    <div className="levelt-prize">
                      3.999 <TeethSvg />
                    </div>
                  </div>
                  <div className="level">
                    <div className="level-name">10 уровней</div>
                    <div className="levelt-prize">
                      999 <TeethSvg />
                    </div>
                  </div>
                  <Button className="fullwidth spiked">КУПИТЬ УРОВНИ</Button>
                </div>
              </div>
            </div>
            <Slider items={battlePassRewards} />
          </div>
          <ProgressBar value={340} max={500}>
            <p>
              <span>До повышения уровня осталовь: </span>
              <span>340/500 ОА</span>
            </p>
          </ProgressBar>
        </div>
        <div className="mb-2">
          <SmallComponentHeading className="gradient-text">
            Что такое Боевой пропуск?
          </SmallComponentHeading>
          <p>
            Боевой пропуск (батлпасс) - это сезонное событие с цепочкой
            последовательных этапов с наградами, которые открываются при
            зарабатывании определенного количества очков пропуска. Обычный
            пропуск доступен всем игрокам абсолютно бесплатно, улучшенный - за
            1000 зуб. После приобретения улучшенного пропуска вы получаете все
            награды за пройденные этапы. Приобрести его вы сможете в любое время
            действия сезона.
          </p>
        </div>
        <div>
          <SmallComponentHeading className="gradient-text">
            Как продвигаться в боевом пропуске?
          </SmallComponentHeading>
          <p>
            Очки активности зарабатываются во время обычной игры на сервере, за
            ваше активное выживание на карте вы получаете каждые 5 минут 1 очко
            активности. Множители не учитываются.
          </p>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default BattlePass;
