import React from "react";
import ComponentContainer from "../../../components/ComponentContainer";
import SmallComponentHeading from "../../../components/Headings/SmallComponentHeading";
import Button from "../../../components/Button";
import RouletteWheel from "../../../components/Rouletts/RouletteWheel";
import "../../../css/fortune-wheel.css";
import img from "../../../assets/img/dinosaur/Изображение.png";
import img1 from "../../../assets/img/dinosaur/Изображение1.png";
import img2 from "../../../assets/img/dinosaur/Изображение2.png";
import img3 from "../../../assets/img/dinosaur/Изображение3.png";
import img4 from "../../../assets/img/dinosaur/Изображение4.png";
import img5 from "../../../assets/img/dinosaur/Изображение5.png";
import img6 from "../../../assets/img/dinosaur/Изображение6.png";
import img7 from "../../../assets/img/dinosaur/Изображение7.png";

import DinosaurCard from "../../../components/DinosaurCard";
import {
  dinosaurSizes,
  dinosaurTierClassNames,
  dinosaurTypes,
} from "../../../utils/consts";
import WheelOfFortune from "../../../components/Rouletts/WheelOfFortune";

const FortuneWheel = () => {
  const cardProps = [
    {
      image: img,
      type: dinosaurTypes.herbivorous,
      name: "НАИМЕНОВАНИЕ",
      tier: 1,
    },
    {
      image: img1,
      type: dinosaurTypes.predator,
      name: "НАИМЕНОВАНИЕ",
      tier: 2,
    },
    {
      image: img2,
      type: dinosaurTypes.herbivorous,
      name: "НАИМЕНОВАНИЕ",
      tier: 2,
    },
    {
      image: img3,
      type: dinosaurTypes.predator,
      name: "НАИМЕНОВАНИЕ",
      tier: 3,
    },
    {
      image: img4,
      type: dinosaurTypes.predator,
      name: "НАИМЕНОВАНИЕ",
      tier: 1,
    },
    {
      image: img5,
      type: dinosaurTypes.herbivorous,
      name: "НАИМЕНОВАНИЕ",
      tier: 3,
    },
    {
      image: img6,
      type: dinosaurTypes.herbivorous,
      name: "НАИМЕНОВАНИЕ",
      tier: 2,
    },
    {
      image: img7,
      type: dinosaurTypes.predator,
      name: "НАИМЕНОВАНИЕ",
      tier: 4,
    },
    {
      image: img3,
      type: dinosaurTypes.predator,
      name: "НАИМЕНОВАНИЕ",
      tier: 3,
    },
    {
      image: img4,
      type: dinosaurTypes.predator,
      name: "НАИМЕНОВАНИЕ",
      tier: 1,
    },
    {
      image: img5,
      type: dinosaurTypes.herbivorous,
      name: "НАИМЕНОВАНИЕ",
      tier: 3,
    },
    {
      image: img6,
      type: dinosaurTypes.herbivorous,
      name: "НАИМЕНОВАНИЕ",
      tier: 2,
    },
  ];

  const tiers = cardProps.map((item) => dinosaurTierClassNames[item.tier]);
  const items = cardProps.map((item) => (
    <DinosaurCard {...item} size={dinosaurSizes.smallest} />
  ));
  // const items = [
  //   <DinosaurCard {...cardProps}  />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  //   <DinosaurCard {...cardProps} />,
  // ];
  return (
    <div className="fortune-wheel-page page">
      <ComponentContainer>
        <div className="fortune-wheel-page-container">
          <RouletteWheel items={items} tiers={tiers} />
          <div className="wheel-information">
            <div className="wheel-informaiton-container">
              <div className="wheel-times">
                <SmallComponentHeading>
                  Прокрутить колесо:
                </SmallComponentHeading>
                <div className="wheel-times-container">
                  <Button className="no-background extra spiked">x1</Button>
                  <Button className="no-background extra spiked">x5</Button>
                  <Button className="no-background extra spiked">x10</Button>
                </div>
              </div>
              <div className="user-rewards">
                <SmallComponentHeading>Ваши награды</SmallComponentHeading>
                <div className="user-rewards-container">
                  <table>
                    {Array(5)
                      .fill(0)
                      .map((_, i) => (
                        <tr>
                          <td>08:28</td>
                          <td className="text-accent">получили</td>
                          <td className="gradient-text success">Трицератопс</td>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
              <div className="last-rewards">
                <SmallComponentHeading>
                  Последние 10 наград
                </SmallComponentHeading>
                <div className="last-rewards-container">
                  <table>
                    <tr>
                      <td>08:28</td>
                      <td className="text-accent">•DEU•†Obito~</td>
                      <td className="gradient-text extra">Трицератопс</td>
                    </tr>
                    <tr>
                      <td>08:29</td>
                      <td className="text-accent">Ник</td>
                      <td className="gradient-text dangerous">
                        Яйцо травоядного
                      </td>
                    </tr>
                    <tr>
                      <td>08:30</td>
                      <td className="text-accent">Ник</td>
                      <td className="gradient-text success">
                        Восстановление HP
                      </td>
                    </tr>
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <tr>
                          <td>08:30</td>
                          <td className="text-accent">Ник</td>
                          <td className="">наименование</td>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default FortuneWheel;
