import React from "react";
import ComponentContainer from "../../components/ComponentContainer";
import UserMenu from "../../components/UserMenu/UserMenu";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import { attentionCardStatuses, attentionCardTypes } from "../../utils/consts";
import AttentionCard from "../../components/Cards/AttentionCards/AttentionCard";

const AdminPanel = () => {
  return (
    <div className="admin-panel-page page page-filter">
      <ComponentContainer className="navigation">
        <UserMenu adminPanel={true} />
        <div className="admin-panel">
          <SmallComponentHeading>Тикеты</SmallComponentHeading>
          <div className="flex-container attention-cards-container admin-panel-container admin-panel-tickets">
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <AttentionCard
                  type={attentionCardTypes.adminPanel}
                  status={attentionCardStatuses.active}
                  server={"№ СЕРВЕРА"}
                  heading={"ПОКУПКА “НАИМЕНОВАНИЕ”"}
                  nickname={"InTmTarasius"}
                  steamId={"Steam ID: 5826471801775651"}
                  ticketDate={"15 мая 2023, 16:50"}
                />
              ))}
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default AdminPanel;
