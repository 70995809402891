import React, { useState } from "react";
import "../css/modal.css";

const Modal = (props) => {
  const [isClosed, setIsClosed] = useState(false);

  function closeModal() {
    setIsClosed(true);
    props.onClose && props.onClose();
  }

  return (
    <>
      {!isClosed && (
        <div className={`modal${!isClosed ? " visible" : " hidden"}`}>
          <div className="modal-fixed">
            <div className="shade" onClick={closeModal} />
            <div className="modal-container">
              <button className="close" onClick={closeModal}>
                X
              </button>
              <div className="modal-children">{props.children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
