import React, { cloneElement, useEffect, useRef, useState } from "react";
import "../../css/roulette-wheel.css";
import { ReactComponent as PointerSvg } from "../../assets/svg/pointer.svg";
import Modal from "../Modal";
import DinosaurCard from "../DinosaurCard";
import { dinosaurSizes, dinosaurTierClassNames } from "../../utils/consts";

const RouletteWheel = (props) => {
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [translateYValue, setTranslateYValue] = useState(0);
  const [prize, setPrize] = useState(null);
  const wheelContainerRef = useRef();
  const wheelRef = useRef();
  const items = props.items || [1];
  const tiers = props.tiers || [];
  const maxSize = props.maxSize || 721;

  const circleDegrees = 360;
  const itemsNumber = items.length;
  const anglePerItem = circleDegrees / itemsNumber;

  const rotationRef = useRef(rotation); // Создаем реф для хранения текущего значения rotation

  useEffect(() => {
    rotationRef.current = rotation; // Обновляем реф при изменении rotation
  }, [rotation]);

  const spinWheel = () => {
    if (isSpinning) return;
    setIsSpinning(true);
    setPrize(null);

    const randomItemIndex = Math.floor(Math.random() * items.length);
    const targetAngle = randomItemIndex * anglePerItem;
    const currentAngle = rotation % circleDegrees;
    const randomError = Math.floor(
      Math.random() * anglePerItem - anglePerItem / 2
    ); // Погрешность

    const currentRotation =
      circleDegrees * 6 - (currentAngle + targetAngle + randomError);

    setRotation(rotation + currentRotation);
    wheelRef.current.addEventListener("transitionend", () =>
      handleTransitionEnd()
    );

    function handleTransitionEnd() {
      showPrize(items[randomItemIndex]);
      //resetAngle();
      setIsSpinning(false);
    }

    return wheelRef.current.removeEventListener(
      "transitionend",
      handleTransitionEnd
    );
  };

  function showPrize(prize) {
    setPrize(prize);
  }

  // function resetAngle() {
  //   if (wheelRef.current) {
  //     let style = wheelRef.current.style;
  //     // Сбрасываем transition
  //     console.log(style, style.transition);
  //     style.transition = "none";

  //     // Получаем текущее значение rotation и вычисляем сброшенное значение
  //     const resetRotation = rotationRef.current % circleDegrees;
  //     console.log("resetRotation: ", resetRotation);

  //     style.transform = `rotate(${resetRotation}deg)`;
  //     // Устанавливаем новое значение rotation
  //     setRotation(resetRotation);

  //     // Используем requestAnimationFrame для сброса transition
  //     requestAnimationFrame(() => {
  //       //style.transition = ""; // Восстанавливаем transition
  //     });
  //   }
  // }

  function calculateTranslateY() {
    setTranslateYValue(
      (wheelContainerRef?.current?.getBoundingClientRect().width -
        wheelContainerRef?.current?.getBoundingClientRect().width / 5) /
        2
    );
  }

  useEffect(() => {
    calculateTranslateY();

    window.addEventListener("resize", calculateTranslateY);

    return () => {
      window.removeEventListener("resize", calculateTranslateY);
    };
  }, []);

  const dinosaurModalPropsChanges = {
    size: dinosaurSizes.largest,
  };

  return (
    <>
      <div className="roulette-wheel">
        <div className="hidden-heading">
          <h1 className="text-2xl font-bold wheel-name center">КОЛЕСО УДАЧИ</h1>
        </div>
        <div
          className="wheel-container"
          ref={wheelContainerRef}
          style={{ width: maxSize + "px", height: maxSize + "px" }}
        >
          <div
            className="wheel"
            style={{
              transform: `rotate(${rotation}deg)`,
            }}
            ref={wheelRef}
          >
            <div className="wheel-background" />
            <div className="wheel-sub-background" />
            {items.map((_, i) => (
              <hr
                key={i}
                style={{
                  transform: `rotate(${
                    i * anglePerItem + anglePerItem / 2 - 90
                  }deg) translateX(50%)`,
                }}
              />
            ))}
            {items.map((item, i) => (
              <>
                <div
                  key={i}
                  className={`wheel-item box-shadow${
                    tiers[i] ? " " + tiers[i] : ""
                  }`}
                  style={{
                    transform: `rotate(${
                      i * anglePerItem
                    }deg) translateY(-${translateYValue}px)`,
                  }}
                >
                  {item}
                </div>
              </>
            ))}
          </div>
          <div className="center-content">
            <h1 className="text-2xl font-bold wheel-name center">
              КОЛЕСО УДАЧИ
            </h1>
            <div className="wheel-button">
              <button
                className="spin-button"
                onClick={spinWheel}
                disabled={isSpinning}
              >
                КРУТИТЬ
              </button>
              <p className="mt-4">У ВАС: 3.000 ОА</p>
              <p>1 ПРОКРУТ = 100 ОА</p>
            </div>
          </div>
          <div className="pointer">
            <PointerSvg />
          </div>
        </div>
      </div>
      {prize && <Modal>{cloneElement(prize, dinosaurModalPropsChanges)}</Modal>}
    </>
  );
};

export default RouletteWheel;
