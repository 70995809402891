import React from "react";
import Button from "../../Button";
import SmallComponentHeading from "../../Headings/SmallComponentHeading";
import {
  guildLevelTexts,
  guildLevelThemes,
  infoCardTypes,
  sizeTypes,
} from "../../../utils/consts";
import GuildInfoCard from "./GuildInfoCard";
import IventInfoCard from "./IventInfoCard";
import "../../../css/info-cards.css";

/*

{
    type: infoCardTypes,
}

*/

const InfoCard = (props) => {
  return (
    <>
      {props.type === infoCardTypes.guild && <GuildInfoCard {...props} />}
      {props.type === infoCardTypes.ivent && <IventInfoCard {...props} />}
    </>
  );
};

export default InfoCard;
