import React from "react";
import { toggleActive } from "../utils/helpers";
import { ReactComponent as LikeSvg } from "../assets/svg/like.svg";
import { ReactComponent as DislikeSvg } from "../assets/svg/dislike.svg";
import "../css/vote.css";

const Vote = (props) => {
  return (
    <div className="vote-container">
      <div
        className={`vote-item vote-like active-handler${
          props.isLiked ? " active" : ""
        }`}
        onClick={toggleActive}
      >
        <LikeSvg /> <span className="vote-count">{props.likeCount}</span>
      </div>
      <div
        className={`vote-item vote-dislike active-handler${
          props.isDisliked ? " active" : ""
        }`}
        onClick={toggleActive}
      >
        <DislikeSvg /> <span className="vote-count">{props.dislikeCount}</span>
      </div>
    </div>
  );
};

export default Vote;
