import React from "react";
import ComponentContainer from "../../../components/ComponentContainer";
import UserMenu from "../../../components/UserMenu/UserMenu";
import SmallComponentHeading from "../../../components/Headings/SmallComponentHeading";
import AttentionCard from "../../../components/Cards/AttentionCards/AttentionCard";
import {
  attentionCardStatuses,
  attentionCardTypes,
} from "../../../utils/consts";

const Offers = () => {
  return (
    <div className="offers-page page">
      <ComponentContainer className="navigation">
        <UserMenu offers={true} />
        <div className="offers">
          <SmallComponentHeading>Активные предложения</SmallComponentHeading>
          <div className="offers-container">
            <div className="flex-container attention-cards-container offers-cards-container">
              {Array(6)
                .fill(0)
                .map((_, i) => (
                  <AttentionCard
                    key={i}
                    type={attentionCardTypes.offers}
                    status={attentionCardStatuses.active}
                    server={"СЕРВЕР"}
                    heading={"Подарки за топ PVP в сезонах"}
                    author={"InTmTarasius"}
                    date={"15 мая 2023, 16:50"}
                  />
                ))}
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default Offers;
