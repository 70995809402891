import React, { useEffect, useState } from "react";
import MiniHeading from "../Headings/MiniHeading";
import { ReactComponent as SearchSvg } from "../../assets/svg/search.svg";
import "../../css/shop-filter.css";
import ComponentContainer from "../ComponentContainer";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import { dinosaurTierClassNames, megaShopTypes } from "../../utils/consts";

const ShopFilter = (props) => {
  const data = props.data || [];
  const [visibleData, setVisibleData] = useState(data);
  const [activeFilters, setActiveFilters] = useState({});

  function onFilter(filter) {
    setActiveFilters({ ...activeFilters, filter: !activeFilters[filter] });
  }

  useEffect(() => {
    setVisibleData([]);
  }, [activeFilters]);

  useEffect(() => {}, [visibleData]);

  const filters = {
    types: megaShopTypes,
    tiers: dinosaurTierClassNames,
  };

  return (
    <div className="shop-filter filter">
      <SmallComponentHeading>Фильтр</SmallComponentHeading>
      <div className="search">
        <button type="button">
          <SearchSvg />
        </button>
        <input type="search" placeholder="Поиск по названию" />
      </div>
      <div className="section">
        <div className="section-title">Тип</div>
        <ul className="no-marker">
          <li>
            <input type="checkbox" id="Травоядные" />
            <label for="Травоядные">Травоядные</label>
          </li>
          <li>
            <input type="checkbox" id="Хищники" />
            <label for="Хищники">Хищники</label>
          </li>
          <li>
            <input type="checkbox" id="Кейсы" />
            <label for="Кейсы">Кейсы</label>
          </li>
          <li>
            <input type="checkbox" id="Бустеры" />
            <label for="Бустеры">Бустеры</label>
          </li>
          <li>
            <input type="checkbox" id="Яйца" />
            <label for="Яйца">Яйца</label>
          </li>
        </ul>
      </div>
      <div className="section">
        <div className="section-title">ТИР</div>
        <ul className="no-marker">
          <li>
            <input type="checkbox" id="I" />
            <label for="I">I</label>
          </li>
          <li>
            <input type="checkbox" id="II" />
            <label for="II">II</label>
          </li>
          <li>
            <input type="checkbox" id="III" />
            <label for="III">III</label>
          </li>
          <li>
            <input type="checkbox" id="IV" />
            <label for="IV">IV</label>
          </li>
        </ul>
      </div>
      <div className="section">
        <div className="section-title">РОСТ</div>
        <ul className="no-marker">
          <li>
            <input type="checkbox" id="Sub" />
            <label for="Sub">Sub</label>
          </li>
          <li>
            <input type="checkbox" id="Adult" />
            <label for="Adult">Adult</label>
          </li>
        </ul>
      </div>
      <div className="section">
        <div className="section-title">ВЕС</div>
        <div className="weight">
          <input type="number" placeholder="от 50 кг" min={50} max={50_000} />
          <input type="number" placeholder="до 50 тонн" min={50} max={50_000} />
        </div>
        <input type="range" min={50} max={50_000} />
      </div>
    </div>
  );
};

export default ShopFilter;
