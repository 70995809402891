import { Link, useLocation } from "react-router-dom";
import userImg from "../../assets/img/avatar/Avatar_100.png";
import { ReactComponent as LogoutSvg } from "../../assets/svg/log_out.svg";
import { ReactComponent as SteamSvg } from "../../assets/svg/steam.svg";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import A from "../A";
import { steamProfile } from "../../utils/consts";
import { ReactComponent as ChangeSvg } from "../../assets/svg/refresh_02.svg";
import { ReactComponent as PlusSquareSvg } from "../../assets/svg/plus_square.svg";
import Button from "../Button";
import { ReactComponent as WalletSvg } from "../../assets/svg/wallet.svg";
import { ReactComponent as OaSvg } from "../../assets/svg/oa.svg";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import "../../css/user-menu.css";
import MiniHeading from "../Headings/MiniHeading";
import { useContext } from "react";
import { Context } from "../..";
import { paths } from "../../paths";
import MegaMenu from "./MegaMenu";
import GuildsMenu from "./GuildsMenu";
import IventsMenu from "./IventsMenu";
import ServerMenu from "./ServerMenu";
import AdminPanel from "../../pages/Admin/AdminPanel";
import AdminPanelMenu from "./AdminPanelMenu";
import OffersMenu from "./OffersMenu";

const UserMenu = (props) => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const page = location.pathname;

  return (
    <div className="user-menu">
      <SmallComponentHeading className="flex">
        Пользователь{" "}
        <Link paths={"#"} className="small-text gradient-text">
          <LogoutSvg />
          выйти
        </Link>
      </SmallComponentHeading>
      <div className="user-info">
        <img src={userImg} alt="Аватарка" />
        <div className="info">
          <span className="user-name">nekolyb</span>
          <span className="user-id gradient-text extra">
            ID: <span>190</span>
          </span>
          <p>
            <A href={steamProfile} className="user-steam">
              <SteamSvg />
              Steam
            </A>
          </p>
        </div>
      </div>
      {props.mega && <MegaMenu />}
      {props.guilds && <GuildsMenu />}
      {props.ivents && <IventsMenu />}
      {props.server && <ServerMenu />}
      {props.adminPanel && <AdminPanelMenu />}
      {props.offers && <OffersMenu />}
    </div>
  );
};

export default UserMenu;
