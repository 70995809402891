import React, { useState } from "react";
import ComponentContainer from "../../components/ComponentContainer";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import { ReactComponent as DoneSvg } from "../../assets/svg/done.svg";
import Button from "../../components/Button";
import { ReactComponent as RefreshSvg } from "../../assets/svg/refresh_02.svg";
import { ReactComponent as MultipleArrowsSvg } from "../../assets/svg/multiple-arrows.svg";
import { ReactComponent as WalletSvg } from "../../assets/svg/wallet.svg";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../../assets/svg/oa.svg";
import MegaCommon from "../../components/MegaProfile/MegaCommon";
import "../../css/mega-bank.css";
import { ReactComponent as ArrowSvg } from "../../assets/svg/arrow_down.svg";

const MegaBank = () => {
  const [rublesToTeethRubles, setRublesToTeethRubles] = useState("");
  const [rublesToTeethTeeths, setRublesToTeethTeeths] = useState("");
  const [oaToTeethOas, setOaToTeethOas] = useState("");
  const [oaToTeethTeeths, setOaToTeethTeeths] = useState("");

  const [addBalanceSum, setAddBalanceSum] = useState("");

  function changeRublesToTeethRubles(e) {
    const rubles = +e.target.value || "";
    setRublesToTeethRubles(rubles);
    setRublesToTeethTeeths(rubles * 100);
  }

  function changeRublesToTeethTeeths(e) {
    const teeths = +e.target.value || "";
    setRublesToTeethTeeths(teeths);
    setRublesToTeethTeeths((teeths / 100).toFixed(2));
  }

  function changeOaToTeethOas(e) {
    const oas = +e.target.value || "";
    setOaToTeethOas(oas);
    setOaToTeethTeeths(oas * 5);
  }

  function changeOaToTeethTeeths(e) {
    const teeths = +e.target.value || "";
    setOaToTeethTeeths(teeths);
    setOaToTeethOas((teeths / 5).toFixed(2));
  }

  return (
    <div className="mega-bank-page page">
      <MegaCommon>
        <div className="mega-bank">
          <SmallComponentHeading>МегаБанк</SmallComponentHeading>
          <div className="changer">
            <SmallComponentHeading>Обменник</SmallComponentHeading>
            <div className="changer-container">
              <div className="changer-item">
                <input
                  type="number"
                  className="text-center border-extra"
                  placeholder="рубли"
                  min={0}
                  value={rublesToTeethRubles}
                  onChange={changeRublesToTeethRubles}
                />
                <MultipleArrowsSvg />
                <input
                  type="number"
                  className="text-center border-extra"
                  placeholder="зубы"
                  min={0}
                  value={rublesToTeethTeeths}
                  onChange={changeRublesToTeethTeeths}
                />
                <Button className="gradient-background extra no-border spiked margin-0">
                  <DoneSvg />
                </Button>
              </div>
              <div className="changer-item">
                <input
                  type="number"
                  className="text-center border-extra"
                  placeholder="OA"
                  min={0}
                  value={oaToTeethOas}
                  onChange={changeOaToTeethOas}
                />
                <MultipleArrowsSvg />
                <input
                  type="number"
                  className="text-center border-extra"
                  placeholder="зубы"
                  min={0}
                  value={oaToTeethTeeths}
                  onChange={changeOaToTeethTeeths}
                />
                <Button className="gradient-background extra no-border spiked margin-0">
                  <DoneSvg />
                </Button>
                <Button className="gradient-background extra no-border spiked margin-0">
                  <RefreshSvg />
                </Button>
              </div>
            </div>
          </div>
          <div className="exchange">
            <SmallComponentHeading>Курс обмена</SmallComponentHeading>
            <div className="exchange-container">
              <div className="exchange-item">
                <WalletSvg /> 100 ₽ <span className="gradient-text">=</span>{" "}
                <TeethSvg /> 10.000 зуб.
              </div>
              <div className="exchange-item">
                <TeethSvg /> 10 зуб <span className="gradient-text">=</span>{" "}
                <OaSvg /> 50 OA
              </div>
            </div>
          </div>
          <div className="balance">
            <SmallComponentHeading>Пополнение баланса</SmallComponentHeading>
            <div className="balance-container">
              <div className="payment-type">
                <Button className="gradient-background extra no-border spiked">
                  выберите способ пополнения <ArrowSvg />
                </Button>
              </div>
              <div className="sum">
                <input
                  type="number"
                  className="text-center"
                  placeholder="сумма"
                  min={0}
                  value={addBalanceSum}
                  onChange={(e) => setAddBalanceSum(e.target.value)}
                />
                <Button className="no-border spiked">
                  <DoneSvg /> ПОПОЛНИТЬ
                </Button>
              </div>
            </div>
          </div>
        </div>
      </MegaCommon>
    </div>
  );
};

export default MegaBank;
