import React from "react";
import Image from "../../assets/img/achievement/valentine 1.png";
import ComponentContainer from "../../components/ComponentContainer";
import ProgressBar from "../../components/ProgressBar";
import "../../css/achievements.css";

const Achievements = () => {
  return (
    <div className="achievements-page">
      <ComponentContainer>
        <ProgressBar value={100} max={100}>
          <img src={Image} alt="" />
          <div className="info">
            <div className="main-info">
              <span className="name">День святого Валентина</span>
              <span className="oa">+15 ОА</span>
              <span className="addition-gift">+ РАМКА ПРОФИЛЯ</span>
            </div>
            <p className="desc">
              Выпадает с праздничных коробочек к Дню святого Валентина
            </p>
          </div>
          <div className="from-to">
            <span className="from">1</span> из <span className="to">1</span>
          </div>
        </ProgressBar>
        <ProgressBar value={10} max={100}>
          <img src={Image} alt="" />
          <div className="info">
            <div className="main-info">
              <span className="name">День святого Валентина</span>
              <span className="oa">+150 ОА</span>
              <span className="addition-gift">+ РАМКА ПРОФИЛЯ</span>
            </div>
            <p className="desc">
              Выпадает с праздничных коробочек к Дню святого Валентина
            </p>
          </div>
          <div className="from-to">
            <span className="from">10</span> из <span className="to">100</span>
          </div>
        </ProgressBar>
        <ProgressBar value={0} max={100}>
          <img src={Image} alt="" />
          <div className="info">
            <div className="main-info">
              <span className="name">День святого Валентина</span>
              <span className="oa">+150 ОА</span>
              <span className="addition-gift">+ РАМКА ПРОФИЛЯ</span>
            </div>
            <p className="desc">
              Выпадает с праздничных коробочек к Дню святого Валентина
            </p>
          </div>
          <div className="from-to">
            <span className="from">0</span> из <span className="to">100</span>
          </div>
        </ProgressBar>
        <ProgressBar value={0} max={100}>
          <img src={Image} alt="" />
          <div className="info">
            <div className="main-info">
              <span className="name">День святого Валентина</span>
              <span className="oa">+150 ОА</span>
              <span className="addition-gift">+ РАМКА ПРОФИЛЯ</span>
            </div>
            <p className="desc">
              Выпадает с праздничных коробочек к Дню святого Валентина
            </p>
          </div>
          <div className="from-to">
            <span className="from">0</span> из <span className="to">100</span>
          </div>
        </ProgressBar>
        <ProgressBar value={0} max={100}>
          <img src={Image} alt="" />
          <div className="info">
            <div className="main-info">
              <span className="name">День святого Валентина</span>
              <span className="oa">+150 ОА</span>
              <span className="addition-gift">+ РАМКА ПРОФИЛЯ</span>
            </div>
            <p className="desc">
              Выпадает с праздничных коробочек к Дню святого Валентина
            </p>
          </div>
          <div className="from-to">
            <span className="from">0</span> из <span className="to">100</span>
          </div>
        </ProgressBar>
        <ProgressBar value={0} max={100}>
          <img src={Image} alt="" />
          <div className="info">
            <div className="main-info">
              <span className="name">День святого Валентина</span>
              <span className="oa">+150 ОА</span>
              <span className="addition-gift">+ РАМКА ПРОФИЛЯ</span>
            </div>
            <p className="desc">
              Выпадает с праздничных коробочек к Дню святого Валентина
            </p>
          </div>
          <div className="from-to">
            <span className="from">0</span> из <span className="to">100</span>
          </div>
        </ProgressBar>
      </ComponentContainer>
    </div>
  );
};

export default Achievements;
