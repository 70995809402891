import React from "react";
import ComponentContainer from "../../components/ComponentContainer";
import UserMenu from "../../components/UserMenu/UserMenu";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import { Link } from "react-router-dom";
import { ReactComponent as EditSvg } from "../../assets/svg/edit.svg";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import "../../css/guilds-my.css";

const GuildsMy = () => {
  return (
    <div className="guild-my-page page">
      <ComponentContainer className="navigation">
        <UserMenu guilds={true} />
        <div className="guild-my">
          <SmallComponentHeading>Название гильдии</SmallComponentHeading>
          <div className="guild-my-container">
            <div className="guild-description">
              <p className="guild-slogan">“Слоган клана распологается тут”</p>
              <textarea placeholder="Описание гильдии" />
              <div className="guild-news">
                <SmallComponentHeading>Новости</SmallComponentHeading>
                <textarea
                  placeholder="Новости гильдии"
                  value='Что может дать вам наша гильдия? - Первое и самое главное - это добрый и дружелюбный коллектив, к каждой новой заявке я подхожу точечно, "для массы" никого не берем. Средний возраст...'
                />
              </div>
              <div className="battles-history">
                <SmallComponentHeading>История битв</SmallComponentHeading>
                <table>
                  <tr>
                    <th>Турнир</th>
                    <th>Дата</th>
                    <th>Место</th>
                    <th>Место</th>
                  </tr>
                  <tr>
                    <td className="gradient-text extra">Название турнира</td>
                    <td>03.08.23</td>
                    <td
                      className={`${
                        1 < 4
                          ? "gradient-text guilds-number big-size bold"
                          : "bold"
                      }`}
                    >
                      1
                    </td>
                    <td>300</td>
                  </tr>
                  <tr>
                    <td className="gradient-text extra">Название турнира</td>
                    <td>03.08.23</td>
                    <td
                      className={`${
                        2 < 4
                          ? "gradient-text guilds-number big-size bold"
                          : "bold"
                      }`}
                    >
                      2
                    </td>
                    <td>300</td>
                  </tr>
                  <tr>
                    <td className="gradient-text extra">Название турнира</td>
                    <td>03.08.23</td>
                    <td
                      className={`${
                        15 < 4
                          ? "gradient-text guilds-number big-size bold"
                          : "bold"
                      }`}
                    >
                      15
                    </td>
                    <td>300</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="guild-my-about">
              <div className="guild-about">
                <SmallComponentHeading className="flex">
                  <span>О клане</span>
                  <div className="buttons">
                    <Link to={"#"} className="small-text">
                      <EditSvg /> редактировать
                    </Link>
                  </div>
                </SmallComponentHeading>
                <div className="table">
                  <div className="tr">
                    <span>Уровень</span>
                    <span>Уровень</span>
                  </div>
                  <div className="tr">
                    <span>Очки</span>
                    <span className="gradient-text bold">71034</span>
                  </div>
                  <div className="tr">
                    <span>Казна</span>
                    <span className="gradient-text extra bold">
                      3.000 зуб. <TeethSvg />
                    </span>
                  </div>
                  <div className="tr">
                    <span>Очков Активности</span>
                    <span className="bold">160.464 ОА</span>
                  </div>
                  <div className="tr">
                    <span>Дата основания</span>
                    <span className="bold">21.08.2023</span>
                  </div>
                </div>
                <div className="guild-achievements">
                  <SmallComponentHeading>Достижения</SmallComponentHeading>
                  <p className="semi-bold">Турнир гильдий</p>
                  <div className="table">
                    <div className="tr">
                      <span>1 место</span>
                      <span className="gradient-text bold">15 раз</span>
                    </div>
                    <div className="tr">
                      <span>2 место</span>
                      <span className="gradient-text bold">32 раза</span>
                    </div>
                    <div className="tr">
                      <span>3 место </span>
                      <span className="gradient-text bold">2 раза</span>
                    </div>
                  </div>
                </div>
                <div className="guild-members">
                  <SmallComponentHeading>
                    Участники{" "}
                    <span className="gradient-text guilds-number">13</span>
                  </SmallComponentHeading>
                  <table className="numerable-table">
                    <tr>
                      <th>Должность</th>
                      <th>Игрок</th>
                    </tr>
                    <tr>
                      <td className="gradient-text success">Лидер</td>
                      <td className="semi-bold">|GG| Kovboy</td>
                    </tr>
                    <tr>
                      <td className="gradient-text success">Заместитель</td>
                      <td className="semi-bold">Ник</td>
                    </tr>
                    <tr>
                      <td className="gradient-text">Участник</td>
                      <td className="semi-bold">Ник</td>
                    </tr>
                  </table>
                  <div className="center">
                    <button className="text-dark center">показать ещё</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default GuildsMy;
