import { BrowserRouter, useLocation } from "react-router-dom";
import TransitionLayer from "./components/TransitionLayer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRouter from "./components/AppRouter";
import "./css/App.css";
import "./css/media-app.css";
import { scripts } from "./utils/scripts";
import ScrollToTopButton from "./components/ScrollToTop";

function App() {
  // scripts();
  return (
    <BrowserRouter>
      <TransitionLayer />
      <div className="page-container">
        <Header />
        <AppRouter />
        <Footer />
      </div>
      <ScrollToTopButton />
    </BrowserRouter>
  );
}

export default App;
