import React from "react";
import Button from "../../Button";
import { guildLevelTexts, guildLevelThemes } from "../../../utils/consts";
import SmallComponentHeading from "../../Headings/SmallComponentHeading";
import { ReactComponent as TeethSvg } from "../../../assets/svg/teeth.svg";
import { ReactComponent as OaSvg } from "../../../assets/svg/oa.svg";

/*
{
    image: string,
    heading: string,
    leader: string,
    members: number,
    points: number,
    teeth: number,
    oa: number,
    level: number
}

*/
const GuildInfoCard = (props) => {
  return (
    <div class={`info-card ${props.type}`}>
      <div className="image">
        <img alt={props.type} src={props.image} />
      </div>
      <div class="info">
        <SmallComponentHeading>{props.heading}</SmallComponentHeading>
        <p className="leader">
          Глава: <span>{props.leader}</span>
        </p>
        <div className="data-container">
          <span className="data">
            Участников: <span>{props.members}</span>
          </span>
          <span className="data">
            Очков: <span>{props.points}</span>
          </span>
          <span className="data">
            Казна: <span>{props.teeth}</span>
            <TeethSvg />
          </span>
          <span className="data">
            OA <span>{props.oa}</span>
            <OaSvg />
          </span>
        </div>
      </div>
      <div class="actions">
        <div className={`level ${guildLevelThemes[props.level]}`}>
          <span className="level-text">
            Уровень
            <br />
            <span>"{guildLevelTexts[props.level]}"</span>
          </span>
          <div className="level-level">{props.level}</div>
        </div>
        <div className="buttons">
          <Button className="no-background spiked extended">ПОДПИСАТЬСЯ</Button>
          <Button className="extra no-background spiked extended">
            ПОДАТЬ ЗАЯВКУ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GuildInfoCard;
