import React from "react";
import Button from "../Button";
import { Link, useLocation } from "react-router-dom";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import { useContext } from "react";
import { Context } from "../..";
import { paths } from "../../paths";

const IventsMenu = () => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const page = location.pathname;

  return (
    <>
      <div className="menu">
        <SmallComponentHeading>Статусы</SmallComponentHeading>
        <div className="buttons">
          <Link
            to={userStore.choosedServerUrl + paths.IventsActive}
            className={
              page === userStore.choosedServerUrl + paths.IventsActive
                ? "active"
                : ""
            }
          >
            <Button className="extra no-border spiked">АКТИВНЫЕ</Button>
          </Link>
          <Link
            to={userStore.choosedServerUrl + paths.IventsAccepted}
            className={
              page === userStore.choosedServerUrl + paths.IventsAccepted
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">ОДОБРЕННЫЕ</Button>
          </Link>
          <Link
            to={userStore.choosedServerUrl + paths.IventsDeclined}
            className={
              page === userStore.choosedServerUrl + paths.IventsDeclined
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">ОТКЛОНЕННЫЕ</Button>
          </Link>
          <Link
            to={userStore.choosedServerUrl + paths.IventsOnModeration}
            className={
              page === userStore.choosedServerUrl + paths.IventsOnModeration
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">НА МОДЕРАЦИИ</Button>
          </Link>
        </div>
      </div>
      <div className="menu">
        <SmallComponentHeading>Категории</SmallComponentHeading>
        <div className="buttons">
          <Link
            to={userStore.choosedServerUrl + paths.IventsActive}
            className={
              page === userStore.choosedServerUrl + paths.IventsActive
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">ВСЕ</Button>
          </Link>
          <Link
            to={userStore.choosedServerUrl + paths.IventsAccepted}
            className={
              page === userStore.choosedServerUrl + paths.IventsAccepted
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">СЕРВЕР</Button>
          </Link>
          <Link
            to={userStore.choosedServerUrl + paths.IventsDeclined}
            className={
              page === userStore.choosedServerUrl + paths.IventsDeclined
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">DISCORD</Button>
          </Link>
          <Link
            to={userStore.choosedServerUrl + paths.IventsOnModeration}
            className={
              page === userStore.choosedServerUrl + paths.IventsOnModeration
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">САЙТ</Button>
          </Link>
          <Link
            to={userStore.choosedServerUrl + paths.IventsOnModeration}
            className={
              page === userStore.choosedServerUrl + paths.IventsOnModeration
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">&#183; Другое</Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default IventsMenu;
