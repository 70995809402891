import React, { useContext } from "react";
import MegaCommon from "../../components/MegaProfile/MegaCommon";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import ProgressBar from "../../components/ProgressBar";
import "../../css/my-statistics.css";
import Image1 from "../../assets/img/achievement/valentine 1.png";
import Image2 from "../../assets/img/achievement/valentine 1 (1).png";
import Image3 from "../../assets/img/achievement/valentine 1 (2).png";
import { Link } from "react-router-dom";
import { paths } from "../../paths";
import { Context } from "../..";
import ShowMore from "../../components/ShowMore";

const MyStatistics = () => {
  const { userStore } = useContext(Context);

  async function showMore() {
    // show more
  }

  return (
    <div className="my-statistics-page page">
      <MegaCommon>
        <div className="my-statistics">
          <SmallComponentHeading>Моя активность</SmallComponentHeading>
          <div className="activity">
            <table>
              <thead>Моя активность</thead>
              <tbody>
                <tr className="name-value">
                  <td className="name">На сервере с</td>
                  <td className="value">11.07.2023</td>
                </tr>
                <tr className="name-value">
                  <td className="name">Общее время в игре</td>
                  <td className="value">155 час.</td>
                </tr>
              </tbody>

              <thead>Статистика</thead>
              <tbody>
                <tr className="name-value">
                  <td className="name">Количество убитых дино</td>
                  <td className="value">67</td>
                </tr>
                <tr className="name-value">
                  <td className="name">Всего дино</td>
                  <td className="value">15</td>
                </tr>
                <tr className="name-value">
                  <td className="name">Выполнено достижений</td>
                  <td className="value">2</td>
                </tr>
                <tr className="name-value">
                  <td className="name">Место в рейтинге</td>
                  <td className="value">35</td>
                </tr>
                <tr className="name-value">
                  <td className="name">Выполнено заданий</td>
                  <td className="value">1</td>
                </tr>
              </tbody>
            </table>

            <SmallComponentHeading>Достижения</SmallComponentHeading>
            <div className="achievements-container">
              <div className="achievement done">
                <img src={Image1} alt="" />
                <div className="info">
                  <div className="main-info">
                    <span className="name">День святого Валентина</span>
                    <span className="addition-gift">+ 0 очков к рейтингу</span>
                  </div>
                  <p className="desc">
                    Выпадает с праздничных коробочек к Дню святого Валентина
                  </p>
                </div>
              </div>
              <div className="achievement done">
                <img src={Image2} alt="" />
                <div className="info">
                  <div className="main-info">
                    <span className="name">Багровый закат</span>
                    <span className="addition-gift">+ 5 очков к рейтингу</span>
                  </div>
                  <p className="desc">
                    Выдается за победу в ивенте "Вампиризм"
                  </p>
                </div>
              </div>
              <div className="achievement">
                <img src={Image3} alt="" />
                <div className="info">
                  <div className="main-info">
                    <span className="name">Галли-Ученик</span>
                    <span className="addition-gift">+ 5 очков к рейтингу</span>
                  </div>
                  <p className="desc">
                    Выдается за покупку 5 адолт Галлимимов.
                  </p>
                  <ProgressBar value={2} max={5}>
                    <span>2/5</span>
                  </ProgressBar>
                </div>
              </div>
              <div className="achievement">
                <img src={Image3} alt="" />
                <div className="info">
                  <div className="main-info">
                    <span className="name">Галли-Ученик</span>
                    <span className="addition-gift">+ 5 очков к рейтингу</span>
                  </div>
                  <p className="desc">
                    Выдается за покупку 5 адолт Галлимимов.
                  </p>
                  <ProgressBar>
                    <span>0/5</span>
                  </ProgressBar>
                </div>
              </div>
              <ShowMore
                to={userStore.choosedServerUrl + paths.Achievements}
                onClick={showMore}
              />
            </div>
          </div>
        </div>
      </MegaCommon>
    </div>
  );
};

export default MyStatistics;
