import React, { useEffect, useState, useRef } from "react";
import Button from "../Button";
import { ReactComponent as PointerSvg } from "../../assets/svg/pointer.svg";
import "../../css/roulette.css";
import ReactDOM from "react-dom/client";
import Modal from "../Modal";
import DinosaurCard from "../DinosaurCard";
import SmallComponentHeading from "../Headings/SmallComponentHeading";

const Roulette = (props) => {
  const [isStarted, setIsStarted] = useState(false);
  const [currentTranslateX, setCurrentTranslateX] = useState(0);
  const [isFirstStart, setIsFirstStart] = useState(true);
  const [prize, setPrize] = useState(null);
  const listRef = useRef(null);
  const scopeRef = useRef(null);
  const cells = 25;
  const Component = props.item;
  const itemsData = props.data || [];
  const targetIndex = 12;
  const [targetElement, setTargetElement] = useState(null);
  const [targetData, setTargetData] = useState({});
  const [isFastRoul, setIsFastRoul] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    (async () => {
      if (isStarted) {
        await generateItems();
      }
    })();
  }, [isStarted]);

  useEffect(() => {
    (async () => {
      await generateItems();
      setIsFirstStart(false);
    })();
  }, []);

  useEffect(() => {
    if (isAnimating) {
      handleAnimation();
    }
  }, [isAnimating]);

  const getItem = () => {
    let item;

    while (!item) {
      const chance = Math.floor(Math.random() * 100);
      itemsData.forEach((elm) => {
        if (chance < elm.chance && !item) item = elm;
      });
    }

    return item;
  };

  console.log(isAnimating);

  function createItem(item) {
    const li = document.createElement("li");
    li.classList.add("list__item");

    const component = React.createElement(Component, {
      ...item,
    });

    const root = ReactDOM.createRoot(li);
    root.render(component);
    return li;
  }

  const generateItems = async () => {
    const list = listRef.current;
    if (list) {
      const itemList = Array.from(
        { length: !isFirstStart ? cells : Math.floor(cells / 2) },
        () => getItem()
      );

      itemList.forEach((item, index) => {
        let li = createItem(item);
        console.log(isFirstStart);
        if (!isFirstStart && index === targetIndex) {
          // Запрос на сервер для получения нужного динозавра
          // const gettedItem = await getItem();
          // item = gettedItem;
          //li.ref = itemRef;
          setTargetElement(li);
          setTargetData(item);
        }

        list.appendChild(li);
      });
      if (!isFirstStart) {
        setIsAnimating(true);
      }
    }
  };

  const start = async (isFast = false) => {
    if (isStarted) return;
    setIsStarted(true);

    if (isFast) {
      setIsFastRoul(true);
    }
  };

  const handleAnimation = () => {
    const list = listRef.current;

    const item = targetElement;
    console.log(list, item);

    if (!list || !item || !item.getBoundingClientRect().width) return;
    const gettedItemTranslateX =
      currentTranslateX +
      item.getBoundingClientRect().left +
      Math.floor(Math.random() * item.getBoundingClientRect().width) -
      scopeRef.current.getBoundingClientRect().width / 2;

    console.log(
      item.getBoundingClientRect().left,
      item.getBoundingClientRect().width,
      Math.floor(Math.random() * item.getBoundingClientRect().width),
      gettedItemTranslateX
    );
    console.log(targetElement, targetData);
    setCurrentTranslateX(gettedItemTranslateX);
    setPrize(null);

    if (isFastRoul) {
      list.style.transition = "0.1s";
    }

    const handleTransitionEnd = () => {
      setIsStarted(false);
      item.classList.add("active");
      setPrize(targetData);

      if (isFastRoul) {
        list.style.transition = "";
        setIsFastRoul(false);
      }

      list.removeEventListener("transitionend", handleTransitionEnd);
      setIsAnimating(false);
    };

    list.addEventListener("transitionend", handleTransitionEnd, {
      once: true,
    });
  };

  return (
    <div className="roulette">
      <PointerSvg className="pointer up" />
      <PointerSvg className="pointer down" />
      <div className="scope" ref={scopeRef}>
        <ul
          className={`list`}
          style={{
            transform: `translate3d(-${currentTranslateX}px, 0, 0)`,
          }}
          ref={listRef}
        ></ul>
      </div>
      <div className="buttons">
        <Button className="back-button black spiked expanded">НАЗАД</Button>
        <Button
          onClick={() => start(false)}
          className="start-button center spiked expanded box-shadow"
        >
          КРУТИТЬ РУЛЕТКУ
        </Button>
        <Button
          className="fast-button black spiked expanded"
          onClick={() => start(true)}
        >
          БЫСТРАЯ ПРОКРУТКА
        </Button>
      </div>
      {prize && (
        <Modal>
          <SmallComponentHeading>Ваш приз:</SmallComponentHeading>
          <DinosaurCard {...prize} />
        </Modal>
      )}
    </div>
  );
};

export default Roulette;
