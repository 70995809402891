import React from "react";
import Button from "../../Button";
import {
  guildLevelTexts,
  guildLevelThemes,
  sizeTypes,
} from "../../../utils/consts";
import SmallComponentHeading from "../../Headings/SmallComponentHeading";
import "../../../css/ivent-info-card.css";
import { Link, useLocation } from "react-router-dom";
import { paths } from "../../../paths";
import { useContext } from "react";
import { Context } from "../../..";

/*

{
    cardType = sizeTypes
    cardType === sizeTypes.compressed

    image: string,
    heading: string,
    date: string

    cardType === sizeTypes.expanded

    image: string,
    heading: string,
    dateStart: string,
    dateEnd: string,
}

*/

const IventInfoCard = (props) => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const page = location.pathname;

  return (
    <div className="info-list">
      <div class={`info-card ${props.type} ${props.sizeType}`}>
        <div className={`image  ${props.sizeType}`}>
          <img alt={props.type} src={props.image} />
        </div>
        {props.sizeType === sizeTypes.expanded && (
          <>
            <div class="info">
              <SmallComponentHeading>{props.heading}</SmallComponentHeading>
              <p className="text-accent font-size-half">ИНФОРМАЦИЯ</p>
              <div className="data-container">
                <p className="data">
                  Сервер:{" "}
                  <span className="gradient-text extra">{props.server}</span>
                </p>
                <p className="data">
                  Дата проведения:{" "}
                  <span className="gradient-text extra">{props.dateStart}</span>
                </p>
                <p className="data">
                  Дата завершения:{" "}
                  <span className="gradient-text extra">{props.dateEnd}</span>
                </p>
              </div>
            </div>
            <div class="actions">
              <div className="buttons">
                <Button className="extra no-background extended">
                  УЧАСТВОВАТЬ
                </Button>
                <Link to={userStore.choosedServerUrl + paths.IventsDetails}>
                  <Button className="no-background extended">ПОДРОБНЕЕ</Button>
                </Link>
              </div>
            </div>
          </>
        )}
        {props.sizeType === sizeTypes.compressed && (
          <div className="info-card-content">
            <div class="info">
              <p className="font-size-half">
                <b>{props.heading}</b>
              </p>
              <div className="data-container">
                <p className="data">
                  <span className="text-accent">{props.date}</span>
                </p>
              </div>
            </div>
            <div class="actions">
              <div className="buttons">
                <Link to={userStore.choosedServerUrl + paths.IventsDetails}>
                  <Button className="extra no-background extended">
                    ПОДРОБНЕЕ
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IventInfoCard;
