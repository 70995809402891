// К ссылкам в начале добавляется название сервера
// Страница выбора сервера находится на '/'

import { serverUrls } from "./utils/consts";

export const paths = {
  Home: "/",
  Profile: "/profile",

  MegaShop: "/megashop",
  MegaProfile: "/megaprofile",
  MyDinosaurs: "/megaprofile/my-dinosaurs",
  MyStorage: "/megaprofile/my-storage",
  MyStatistics: "/megaprofile/my-statistics",
  MegaBank: "/megaprofile/megabank",

  Ivents: "/ivents",
  IventsDetails: "/ivents/details",
  IventsAccepted: "/ivents/accepted",
  IventsActive: "/ivents/active",
  IventsDeclined: "/ivents/declined",
  IventsOnModeration: "/ivents/on-moderation",

  Guilds: "/guilds",
  GuildsCreate: "/guilds/create",
  GuildsSubs: "/guilds/subs",
  GuildsMy: "/guilds/my",

  Achievements: "/achievements",
  UsersLeaderboard: "/leaderboard/users",
  DinosaursLeaderboard: "/leaderboard/dinosaurs",

  BattlePass: "/battlepass",
  FortuneWheel: "/fortunewheel",
  RoulettePage: "/roulette",

  Administration: "/administration",
  Offers: "/offers/active",
  OfferDetails: "/offers/:id",
  AdminPanel: "/admin/panel",
};

export const independentPaths = {
  TransitionPage: "/",
  NotFound: "*",
  Rules: serverUrls.legacy + "/rules",
  RulesEvrima: serverUrls.evrima + "/rules",
};
