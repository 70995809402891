import React, { useContext, useState } from "react";
import ShopFilter from "../components/MegaShop/ShopFilter";
import Catalog from "../components/MegaShop/Catalog";
import "../css/megashop.css";
import { Context } from "..";
import { dinosaurTypes, megaShopTypes, pageNames } from "../utils/consts";
import ComponentContainer from "../components/ComponentContainer";
import dinosaurImg1 from "../assets/img/dinosaur/Изображение.png";
import dinosaurImg2 from "../assets/img/dinosaur/Изображение1.png";
import dinosaurImg3 from "../assets/img/dinosaur/Изображение2.png";
import dinosaurImg4 from "../assets/img/dinosaur/Изображение3.png";
import dinosaurImg5 from "../assets/img/dinosaur/Изображение4.png";
import dinosaurImg6 from "../assets/img/dinosaur/Изображение5.png";
import dinosaurImg7 from "../assets/img/dinosaur/Изображение6.png";
import dinosaurImg8 from "../assets/img/dinosaur/Изображение7.png";
import dailyImg from "../assets/img/case/daily.png";
import greenImg from "../assets/img/case/green-case.png";
import luckyImg from "../assets/img/case/lucky-case.png";
import mediumImg from "../assets/img/case/medium.png";

const MegaShop = () => {
  const { userStore } = useContext(Context);
  userStore.setPageName(pageNames.MegaShop);

  const casesData = [
    {
      image: luckyImg,
      teeth: 75,
      oa: 300,
    },
    {
      image: greenImg,
      teeth: 50,
      oa: 200,
    },
    {
      image: mediumImg,
      teeth: 100,
      oa: 400,
    },
    {
      image: dailyImg,
      text: "БЕСПЛАТНО",
    },
  ];

  casesData.forEach((item) => {
    item.type = megaShopTypes.cases;
  });

  const dinosaursData = [
    {
      image: dinosaurImg1,
      tier: 1,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
    {
      image: dinosaurImg2,
      tier: 2,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
    {
      image: dinosaurImg3,
      tier: 3,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
    {
      image: dinosaurImg4,
      tier: 4,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
    {
      image: dinosaurImg5,
      tier: 1,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.predator,
    },
    {
      image: dinosaurImg6,
      tier: 2,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.predator,
    },
    {
      image: dinosaurImg7,
      tier: 3,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.predator,
    },
    {
      image: dinosaurImg8,
      tier: 4,
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.predator,
    },
  ];

  const catalogData = [...casesData, ...dinosaursData];

  // const [visibleCases, setVisibleCases] = useState(casesData);
  // const [visibleDinosaurs, setVisibleDinosaurs] = useState(dinosaursData);
  const [visibleData, setVisibleData] = useState(catalogData);

  function onFilter(filteredData) {
    setVisibleData(filteredData);
  }

  return (
    <div className="megashop-page">
      <ComponentContainer className="navigation">
        <ShopFilter data={catalogData} onFilter={onFilter} />
        <Catalog data={visibleData} />
      </ComponentContainer>
    </div>
  );
};

export default MegaShop;
