import React from "react";
import { dinosaurTypes } from "../utils/consts";
import { ReactComponent as Leaflet } from "../assets/svg/leaflet.svg";
import { ReactComponent as Meat } from "../assets/svg/meat.svg";
import "../css/dinosaur-card.css";

/*

{
  type: dinosaurTypes,
  tier: number,
  image: img_src,
  text: string,
  teeth: string,
  oa: string,
  hint: string,
  size: string
}

*/

const DinosaurCard = (props) => {
  const type = dinosaurTypes[props.type];
  const tier = props.tier;

  const svgTypes = {
    herbivorous: <Leaflet />,
    predator: <Meat />,
  };
  const dinosaurTiers = {
    1: "first",
    2: "second",
    3: "third",
    4: "fourth",
  };

  return (
    <div
      className={`dinosaur-card ${props.size ? props.size : ""} ${
        dinosaurTiers[tier]
      } ${type}`}
    >
      <div className={`card`}>
        {props.hint && (
          <div className="hint">
            <p>{props.hint}</p>
          </div>
        )}
        <div
          className="card-background"
          style={{
            backgroundImage: `url(${props.image})`,
          }}
        />
        {svgTypes[type]}
        <div className="dinosaur-card-info">
          {tier && <p>{tier} ТИР</p>}
          {props.name && (
            <p className={"name " + dinosaurTiers[tier]}>{props.name}</p>
          )}
        </div>
      </div>
      {props.text && <div className="text">{props.text}</div>}
      {props.teeth && <div className="teeth">{props.teeth} зуб.</div>}
      {props.oa && <div className="oa">{props.oa} ОА</div>}
    </div>
  );
};

export default DinosaurCard;
