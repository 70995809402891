import React from "react";
import "../../css/small-component-heading.css";

const SmallComponentHeading = (props) => {
  return (
    <p
      className={`small-component-heading${
        props.className ? " " + props.className : ""
      }`}
    >
      {props.children}
    </p>
  );
};

export default SmallComponentHeading;
