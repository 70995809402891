import React, { useState } from "react";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import Button from "../../components/Button";
import { ReactComponent as EditSvg } from "../../assets/svg/edit.svg";
import { ReactComponent as PlusSquareSvg } from "../../assets/svg/plus_square.svg";
import "../../css/my-dinosaurs.css";
import ComponentContainer from "../../components/ComponentContainer";
import UserMenu from "../../components/UserMenu/UserMenu";
import MegaCommon from "../../components/MegaProfile/MegaCommon";
import Modal from "../../components/Modal";
import dinosaurImg1 from "../../assets/img/dinosaur/Изображение.png";
import dinosaurImg2 from "../../assets/img/dinosaur/Изображение1.png";
import dinosaurImg3 from "../../assets/img/dinosaur/Изображение2.png";
import dinosaurImg4 from "../../assets/img/dinosaur/Изображение3.png";
import DinosaurCard from "../../components/DinosaurCard";
import { dinosaurSizes, dinosaurTypes } from "../../utils/consts";

const MyDinosaurs = () => {
  const [userDinosaurs, setUserDinosaurs] = useState([]);
  const starterDinosaursData = [
    {
      image: dinosaurImg1,
      tier: "2",
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
    {
      image: dinosaurImg2,
      tier: "2",
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
    {
      image: dinosaurImg3,
      tier: "2",
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
    {
      image: dinosaurImg4,
      tier: "2",
      name: "НАИМЕНОВАНИЕ",
      type: dinosaurTypes.herbivorous,
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  function openModal() {
    setIsModalVisible(true);
  }

  function closeModal() {
    setIsModalVisible(false);
  }

  async function chooseDinosaur(dinosaurData) {
    if (userDinosaurs.length >= 3) return;
    setUserDinosaurs([...userDinosaurs, dinosaurData]);
    setIsModalVisible(false);
  }

  return (
    <>
      <div className="my-dinosaurs-page page">
        <MegaCommon>
          <div className="my-dinosaurs">
            <SmallComponentHeading className="flex svg-text">
              <span>Мои динозавры</span>
              <div className="buttons">
                <Button className="no-background border-extra spiked">
                  <EditSvg /> редактировать
                </Button>
                <Button className="no-background border-extra spiked">
                  <PlusSquareSvg /> КУПИТЬ ДИНОЗАВРОВ
                </Button>
              </div>
            </SmallComponentHeading>
            <div className="my-dinosaurs-container">
              {userDinosaurs.length > 0 &&
                userDinosaurs.map((dinosaurData, i) => (
                  <DinosaurCard
                    key={i}
                    {...dinosaurData}
                    size={dinosaurSizes.big}
                  />
                ))}
              {userDinosaurs.length < 3 &&
                Array(3 - userDinosaurs.length)
                  .fill()
                  .map((_, index) => (
                    <div
                      key={index}
                      className="empty-card background-accent-black"
                    >
                      <div className="card-body">
                        <p>
                          <b>Пустой слот</b>
                        </p>
                        <p>Вы можете зайти в игру и выбрать динозавра</p>
                        <Button
                          className="no-background border-extra spiked"
                          onClick={openModal}
                        >
                          АКТИВИРОВАТЬ СЛОТ
                        </Button>
                      </div>
                    </div>
                  ))}
              <div className="empty-card gradient-background extra buy-slot">
                <div className="card-body">
                  <p>
                    <b>Купить слот</b>
                  </p>
                  <p>Вы можете зайти в игру и выбрать динозавра</p>
                  <Button className="no-border gradient-background extra spiked">
                    КУПИТЬ СЛОТ
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </MegaCommon>
      </div>
      {isModalVisible && (
        <Modal onClose={closeModal}>
          <SmallComponentHeading>
            ВЫБЕРИТЕ НАЧАЛЬНОГО ДИНОЗАВРА
          </SmallComponentHeading>
          <div className="flex-container">
            {starterDinosaursData.map((data, i) => (
              <div className="flex-item">
                <DinosaurCard key={i} {...data} size={dinosaurSizes.big} />
                <Button
                  className="no-background border-extra"
                  onClick={() => chooseDinosaur(data)}
                >
                  Выбрать
                </Button>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default MyDinosaurs;
