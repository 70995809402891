import React from "react";
import ComponentContainer from "../../components/ComponentContainer";
import UserMenu from "../../components/UserMenu/UserMenu";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import Button from "../../components/Button";
import { ReactComponent as SortSvg } from "../../assets/svg/sort.svg";
import { ReactComponent as PlusSquareSvg } from "../../assets/svg/plus_square.svg";
import "../../css/my-storage.css";
import DinosaurCard from "../../components/DinosaurCard";
import dailyImg from "../../assets/img/case/daily.png";
import BusterImg from "../../assets/img/busters/buster.png";
import EggImg1 from "../../assets/img/eggs/spinosaur.png";
import EggImg2 from "../../assets/img/eggs/puertosaur.png";
import MegaCommon from "../../components/MegaProfile/MegaCommon";
import { dinosaurSizes } from "../../utils/consts";

const MyStorage = () => {
  const cases = {
    image: dailyImg,
  };
  const busters = {
    image: BusterImg,
  };
  const eggs = [
    {
      image: EggImg1,
    },
    {
      image: EggImg2,
    },
  ];
  return (
    <div className="my-storage-page page mega-profile">
      <MegaCommon>
        <div className="my-storage">
          <SmallComponentHeading className="flex svg-text">
            <span>Хранилище</span>
            <div className="buttons">
              <Button className="no-background border-extra spiked">
                <SortSvg /> СОРТИРОВАТЬ
              </Button>
              <Button className="no-background border-extra spiked">
                <PlusSquareSvg /> КУПИТЬ ДИНОЗАВРОВ
              </Button>
            </div>
          </SmallComponentHeading>
          <div className="my-storage-container">
            <SmallComponentHeading>Кейсы</SmallComponentHeading>
            <div className="cases flex-container">
              <DinosaurCard {...cases} size={dinosaurSizes.normal} />
              <DinosaurCard {...cases} size={dinosaurSizes.normal} />
              <DinosaurCard {...cases} size={dinosaurSizes.normal} />
              <DinosaurCard {...cases} size={dinosaurSizes.normal} />
              <DinosaurCard {...cases} size={dinosaurSizes.normal} />
            </div>
            <SmallComponentHeading>Бустеры</SmallComponentHeading>
            <div className="busters flex-container">
              <DinosaurCard {...busters} size={dinosaurSizes.normal} />
            </div>
            <SmallComponentHeading>Яйца</SmallComponentHeading>
            <div className="eggs flex-container">
              <DinosaurCard {...eggs[0]} size={dinosaurSizes.normal} />
              <DinosaurCard {...eggs[1]} size={dinosaurSizes.normal} />
            </div>
          </div>
        </div>
      </MegaCommon>
    </div>
  );
};

export default MyStorage;
