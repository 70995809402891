import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { paths } from "../paths";
import { Context } from "..";

const ShowMore = (props) => {
  const { userStore } = useContext(Context);

  return (
    <div className="show-more center">
      <Link className="gradient-text" {...props}>
        Показать ещё
      </Link>
    </div>
  );
};

export default ShowMore;
