import React, { useContext } from "react";
import { Context } from "..";
import avatar from "../assets/img/avatar/Avatar_50.png";
import { ReactComponent as Oa } from "../assets/svg/oa.svg";
import { ReactComponent as Teeth } from "../assets/svg/teeth.svg";
import "../css/header.css";
import NavigationLinks from "./NavigationLinks";

const Header = () => {
  const { userStore } = useContext(Context);

  return (
    <header>
      {
        /* {userStore.isAuth && ( */ true && (
          <div className="user-header">
            <span className="teefs">
              3.000 зуб. <Teeth />
            </span>
            <img src={avatar} alt="аватар" />
            <span className="oa">
              <Oa /> 3.000 ОА
            </span>
          </div>
        )
      }
      <NavigationLinks />
    </header>
  );
};

export default Header;
