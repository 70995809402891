import { useContext } from "react";
import { ReactComponent as DoorSvg } from "../assets/svg/possibility/door.svg";
import { ReactComponent as FinishFlagSvg } from "../assets/svg/possibility/finish-flag.svg";
import { ReactComponent as FlagSvg } from "../assets/svg/possibility/flag.svg";
import { ReactComponent as GridSvg } from "../assets/svg/possibility/grid.svg";
import { ReactComponent as HeadphonesSvg } from "../assets/svg/possibility/headphones.svg";
import { ReactComponent as PeopleSvg } from "../assets/svg/possibility/people.svg";
import { ReactComponent as StarSvg } from "../assets/svg/possibility/star.svg";
import { ReactComponent as StarsSvg } from "../assets/svg/possibility/stars.svg";
import { ReactComponent as TimeSvg } from "../assets/svg/possibility/time.svg";
import Button from "../components/Button";
import ComponentHeading from "../components/Headings/ComponentHeading";
import MiniHeading from "../components/Headings/MiniHeading";
import PossibilityCard from "../components/PossibilityCard";
import ProgressBar from "../components/ProgressBar";
import {
  dinosaurSizes,
  dinosaurTypes,
  pageAdditions,
  pageNames,
  serverUrls,
  wpImagePath,
} from "../utils/consts";

import dinosaurImg1 from "../assets/img/dinosaur/Изображение.png";
import dinosaurImg2 from "../assets/img/dinosaur/Изображение1.png";
import dinosaurImg3 from "../assets/img/dinosaur/Изображение2.png";
import dinosaurImg4 from "../assets/img/dinosaur/Изображение3.png";
import dinosaurImg5 from "../assets/img/dinosaur/Изображение4.png";
import dinosaurImg6 from "../assets/img/dinosaur/Изображение5.png";
import dinosaurImg7 from "../assets/img/dinosaur/Изображение6.png";
import dinosaurImg8 from "../assets/img/dinosaur/Изображение7.png";
import DinosaurCard from "../components/DinosaurCard";
import SubHeading from "../components/Headings/SubHeading";

import { Context } from "..";
import HelperHeading from "../components/Headings/HelperHeading";
import NumerableList from "../components/NumerableList";
import "../css/home.css";
import { Link } from "react-router-dom";
import { independentPaths, paths } from "../paths";

const Home = () => {
  const { userStore } = useContext(Context);
  userStore.setPageName(pageNames.Home, pageAdditions.Home);

  return (
    <>
      <div className="home-page">
        <div className="inside-container component-container">
          <div className="progress-bars">
            <div className="progress-bar-content">
              <p className="sub-heading">MEGALODON SURVIVAL (RU)</p>
              <ProgressBar value={55} max={150} />
              <Button className="no-background extra">НАЧАТЬ ИГРАТЬ</Button>
            </div>
            <div className="progress-bar-content">
              <p className="sub-heading">MEGALODON SANDBOX (RU)</p>
              <ProgressBar value={5} max={50} />
              <Button className="no-background extra">НАЧАТЬ ИГРАТЬ</Button>
            </div>
          </div>
          <div className="center mb-lg">
            <Button className="no-border extra">Войти в профиль</Button>
          </div>
          <div className="rules back-image">
            <div className="heading-container">
              <ComponentHeading>ПРАВИЛА СЕРВЕРА</ComponentHeading>
              <MiniHeading>рекомендовано к ознакомлению</MiniHeading>
            </div>
            <NumerableList>
              <ol>
                <li>
                  <b>Правила поведения:</b> В игре требуется вести себя
                  адекватно и уважительно по отношению к другим игрокам.
                  Запрещены любые формы оскорблений, угроз, провокаций и
                  шантажа. Поддерживайте дружественную и толерантную атмосферу.
                </li>
                <li>
                  <b>Запрет на мошенничество:</b> Запрещено использование читов,
                  багов или любых других способов нечестной игры. Не пытайтесь
                  получить несправедливое преимущество над другими игроками.
                </li>
                <li>
                  <b>Запрет на использование нецензурной лексики:</b> Запрещено
                  использование нецензурной лексики публично или в приватных
                  сообщениях. Поддерживайте вежливое общение.
                </li>
              </ol>
            </NumerableList>
          </div>
          <div className="center">
            <Link
              to={
                userStore.choosedServerUrl === serverUrls.evrima
                  ? independentPaths.RulesEvrima
                  : independentPaths.Rules
              }
            >
              <Button className="accent no-border">ПОЛНЫЙ СПИСОК ПРАВИЛ</Button>
            </Link>
          </div>
        </div>
        <div className="component-container">
          <ComponentHeading>Возможности сервера и сайта</ComponentHeading>
          <div className="flex-container">
            <PossibilityCard
              image={<PeopleSvg />}
              title="Многопользовательская игра"
              text="Сервер предоставляет возможность играть с другими игроками со всего мира. Вы можете кооперироваться с другими игроками, составлять команды и вместе исследовать удивительный мир динозавров."
            />
            <PossibilityCard
              image={<FlagSvg />}
              title="Захватывающие задания и квесты"
              text="Сервер предлагает интересные и захватывающие задания и квесты для всех игроков."
            />
            <PossibilityCard
              image={<StarSvg />}
              title="Разнообразие динозавров"
              text="Сервер предлагает широкий выбор различных видов динозавров для игроков. Каждый динозавр имеет свои уникальные характеристики и способности, что позволяет игрокам выбирать тот, который больше всего подходит их стилю игры."
            />
            <PossibilityCard
              image={<StarsSvg />}
              title="Развитие и улучшение"
              text="Сервер предоставляет возможность развивать своего динозавра, повышая его уровень и улучшая его навыки. Вы сможете приобретать новые атаки, защиту и специальные способности, чтобы стать еще более мощным и эффективным в битвах."
            />
            <PossibilityCard
              image={<DoorSvg />}
              title="Создание гильдий"
              text="Гильдия - это возможность создать свое небольшое сообщество из друзей, единомышленников и просто хороших людей. Вы можете создать свою атмосферу, где всем будет комфортно."
            />
            <PossibilityCard
              image={<FinishFlagSvg />}
              title="Онлайн соревнования"
              text="Сервер регулярно организует различные соревнования и турниры для игроков. Вы сможете соревноваться с другими игроками, доказывая свое мастерство и получая призы и награды."
            />
            <PossibilityCard
              image={<GridSvg />}
              title="Система слотов"
              text="СЛОТЫ – место, где будет храниться динозавр, слоты можно деактивировать и активировать. Таким образом, игрок может хранить на одном аккаунте несколько динозавров."
            />
            <PossibilityCard
              image={<TimeSvg />}
              title="Постоянные обновления и нововведения"
              text="Администрация сервера и сайта постоянно работает над обновлениями и добавлением новых функций и возможностей. Это позволяет игрокам всегда находить что-то новое и интересное в игре."
            />
            <PossibilityCard
              image={<HeadphonesSvg />}
              title="Поддержка и помощь"
              text="Сервер и сайт предоставляют поддержку и помощь игрокам. Вы можете обратиться к администрации сервера с любыми вопросами, проблемами или предложениями и получить профессиональную поддержку."
            />
          </div>
        </div>

        <div className="center component-container description back-image">
          <div className="inside-container">
            <ComponentHeading>Возможности сервера и сайта</ComponentHeading>
            <HelperHeading className="shadow">
              делают игру на Megalodon увлекательной, разнообразной и
              динамичной.
            </HelperHeading>
            <SubHeading>
              Игроки могут наслаждаться исследованием, сражениями, а также
              взаимодействовать с другими игроками, создавая уникальный игровой
              опыт.
            </SubHeading>
            <Button className="no-border extra">Войти в мегапрофиль</Button>
          </div>
        </div>
        <div className="megashop component-container">
          <ComponentHeading>МегаШоп</ComponentHeading>
          <div className="catalog-content flex-container">
            <DinosaurCard
              image={dinosaurImg1}
              tier="1"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.herbivorous}
              size={dinosaurSizes.largest}
            />
            <DinosaurCard
              image={dinosaurImg2}
              tier="2"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.herbivorous}
              size={dinosaurSizes.largest}
            />
            <DinosaurCard
              image={dinosaurImg3}
              tier="3"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.herbivorous}
              size={dinosaurSizes.largest}
            />
            <DinosaurCard
              image={dinosaurImg4}
              tier="4"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.herbivorous}
              size={dinosaurSizes.largest}
            />
            <DinosaurCard
              image={dinosaurImg5}
              tier="1"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.predator}
              size={dinosaurSizes.largest}
            />
            <DinosaurCard
              image={dinosaurImg6}
              tier="2"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.predator}
              size={dinosaurSizes.largest}
            />
            <DinosaurCard
              image={dinosaurImg7}
              tier="3"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.predator}
              size={dinosaurSizes.largest}
            />
            <DinosaurCard
              image={wpImagePath + dinosaurImg8}
              tier="1"
              name="НАИМЕНОВАНИЕ"
              type={dinosaurTypes.predator}
              size={dinosaurSizes.largest}
            />
          </div>
          <div className="center">
            <Link to={userStore.choosedServerUrl + paths.MegaShop}>
              <Button className="accent no-border">Все динозавры</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
