import React from "react";
import "../css/numerable-list.css";

const NumerableList = (props) => {
  let additionClasses = `${props.isTop ? "top" : ""} ${
    props.noMarker ? "no-marker" : ""
  } ${props.placeWord ? "place-word" : ""}`;

  return (
    <div
      {...props}
      className={`numerable-list ${additionClasses} ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default NumerableList;
