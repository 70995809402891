import React from "react";
import "../../css/helper-heading.css";

const HelperHeading = (props) => {
  return (
    <p
      {...props}
      className={`helper-heading ${props.className ? props.className : ""}`}
    >
      {props.children}
    </p>
  );
};

export default HelperHeading;
