import React from "react";
import MiniHeading from "./Headings/MiniHeading";
import "../css/possibility-card.css";

const PossibilityCard = (props) => {
  return (
    <div className="possibility-card">
      {props.image && (
        <div className="possibility-card-image">{props.image}</div>
      )}
      <div className="possibility-card-text">
        {props.title && <b>{props.title}</b>}
        {props.text && <p>{props.text}</p>}
      </div>
    </div>
  );
};

export default PossibilityCard;
