import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowLeft } from "../assets/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/arrow-right.svg";
import "../css/slider.css";

const Slider = (props) => {
  const [currentTranslateX, setCurrentTranslateX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef();
  const startX = useRef(0);
  const initialTranslateX = useRef(0);

  const items = props.items;

  const [itemWidth, setItemWidth] = useState(0);
  const [itemPaddingLeft, setItemPaddingLeft] = useState(0);
  const [visibleWidth, setVisibleWidth] = useState(0);
  const [minRightTranslateX, setMinRightTranslateX] = useState(0);

  useEffect(() => {
    const visibleScreen = sliderRef.current.offsetWidth;
    console.log("visible screen: ", visibleScreen);
    setVisibleWidth(visibleScreen);
    const currentPaddingLeft = parseFloat(
      document.querySelector(".slider-item").style.paddingLeft
    );
    console.log("currentPaddingLeft: ", currentPaddingLeft);
    const itemSize =
      (sliderRef.current.scrollWidth - currentPaddingLeft * items.length) /
      items.length;
    console.log("itemSize: ", itemSize);
    setItemWidth(itemSize);
    const itemsInScreen = Math.floor(visibleScreen / itemSize);
    console.log("itemsInScreen: ", itemsInScreen);
    const itemPaddingLeft = Math.floor(
      (visibleScreen - itemsInScreen * itemSize) / itemsInScreen
    );
    console.log("itemPaddingLeft: ", itemPaddingLeft);
    setItemPaddingLeft(itemPaddingLeft);

    setMinRightTranslateX(
      -1 * ((itemSize + itemPaddingLeft) * items.length - visibleScreen)
    );
    console.log(
      "MinRightTranslateX: ",
      -1 * ((itemSize + itemPaddingLeft) * items.length)
    );
  }, [items?.length, sliderRef?.current?.offsetWidth]);

  const slideRight = () => {
    let nextSlide = Math.max(
      minRightTranslateX,
      currentTranslateX - visibleWidth
    );
    setCurrentTranslateX(nextSlide);
  };

  const slideLeft = () => {
    let nextSlide = Math.max(
      minRightTranslateX,
      currentTranslateX + visibleWidth
    );
    if (nextSlide > 0) {
      setCurrentTranslateX(0);
      return;
    }
    setCurrentTranslateX(nextSlide);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    startX.current = e.clientX || e.touches[0].clientX;
    initialTranslateX.current = currentTranslateX;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const clientX = e.clientX || e.touches[0].clientX;
    const diff = clientX - startX.current;
    setCurrentTranslateX(initialTranslateX.current + diff);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging || itemWidth === 0) return;
    const itemFullWidth = itemWidth + itemPaddingLeft;
    const roundedTranslateX =
      itemFullWidth * Math.round(currentTranslateX / itemFullWidth);
    const leftRoundedTranslateX = roundedTranslateX + itemFullWidth;

    let closestRound;

    if (roundedTranslateX < minRightTranslateX) {
      closestRound = minRightTranslateX;
    } else if (leftRoundedTranslateX > 0) {
      closestRound = 0;
    } else {
      closestRound =
        currentTranslateX - roundedTranslateX <=
        leftRoundedTranslateX - currentTranslateX
          ? roundedTranslateX
          : leftRoundedTranslateX;
    }
    setCurrentTranslateX(closestRound);
  }, [
    currentTranslateX,
    isDragging,
    itemWidth,
    minRightTranslateX,
    itemPaddingLeft,
  ]);

  if (!items || !items.length > 0) {
    return <></>;
  }

  return (
    <>
      {items && (
        <div className="slider-container">
          <div
            className="slider-arrow left"
            style={{ opacity: currentTranslateX === 0 ? "0" : "1" }}
          >
            <ArrowLeft onClick={slideLeft} />
          </div>
          <div className="scope">
            <div
              className="slider"
              ref={sliderRef}
              style={{ transform: `translate3d(${currentTranslateX}px, 0, 0)` }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchMove={handleMouseMove}
              onTouchEnd={handleMouseUp}
              onTouchCancel={handleMouseUp}
            >
              {items.map((Item, i) => (
                <div
                  className="slider-item"
                  key={i}
                  style={{
                    paddingLeft: itemPaddingLeft + "px",
                  }}
                >
                  {Item}
                </div>
              ))}
            </div>
          </div>
          <div
            className="slider-arrow right"
            style={{
              opacity: currentTranslateX === minRightTranslateX ? "0" : "1",
            }}
          >
            <ArrowRight onClick={slideRight} />
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
