import React from "react";
import ComponentContainer from "../../../components/ComponentContainer";
import UserMenu from "../../../components/UserMenu/UserMenu";
import SmallComponentHeading from "../../../components/Headings/SmallComponentHeading";
import Button from "../../../components/Button";
import NumerableList from "../../../components/NumerableList";
import { ReactComponent as OaSvg } from "../../../assets/svg/oa.svg";
import "../../../css/ivents-details.css";
import { statusClasses, statusTexts } from "../../../utils/consts";
import { Link, useLocation } from "react-router-dom";
import { paths } from "../../../paths";
import { useContext } from "react";
import { Context } from "../../..";

const IventsDetails = () => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const page = location.pathname;

  return (
    <div className="ivents-details-page ivents-page page">
      <ComponentContainer className="navigation">
        <UserMenu ivents={true} />
        <div className="ivents">
          <div className="ivents-container">
            <SmallComponentHeading className="flex">
              <span>Deadly Duo (Саб Трайк + Дьябло)</span>
              <Link
                to={userStore.choosedServerUrl + paths.Ivents}
                className="gradient-text font-size-1"
              >
                назад
              </Link>
            </SmallComponentHeading>
            <p className="data">
              Дата проведения:{" "}
              <span className="gradient-text extra">
                02 августа 2023, 18:30
              </span>
            </p>
            <p className="data multiple">
              Сервер:{" "}
              <span className="gradient-text extra">Megaladon Evrima</span>
              <span>Условие участия: Зарегистрированный пользователь</span>
            </p>
            <Button className="no-background border-extra start">
              УЧАСТВОВАТЬ
            </Button>
            <div className="gradient-background extra attention-text">
              Смертельный дуэт.Командные бои на выбывание 2х2 раз в месяц!
              Зарегистрируйтесь вместе с напарником и примите участие в битве за
              звание "Роковой дуэт" .================= ================= Найдите
              напарника, договоритесь о совместном участии Придумайте название
              своей команды и отпишитесь в ЛС.coltman До 17.55 Дино этого месяца
              - Саб Трайк + Дьябло
            </div>
            <div className="prices">
              <SmallComponentHeading>Награды и призы</SmallComponentHeading>
              <NumerableList noMarker={true} placeWord={true}>
                <ol>
                  <li>
                    <SmallComponentHeading>
                      <OaSvg /> 1.000 OA
                    </SmallComponentHeading>
                  </li>
                  <li>
                    <SmallComponentHeading>
                      <OaSvg /> 500 OA
                    </SmallComponentHeading>
                  </li>
                  <li>
                    <SmallComponentHeading>
                      <OaSvg /> 200 OA
                    </SmallComponentHeading>
                  </li>
                </ol>
              </NumerableList>
            </div>
            <div className="participants">
              <SmallComponentHeading>
                Участники{" "}
                <span className="gradient-text font-size-2-25">15</span>
              </SmallComponentHeading>
              <table className="numerable-table">
                <tr>
                  <th>Игрок</th>
                  <th>Уровень</th>
                  <th>Гильдия</th>
                  <th>Discord</th>
                  <th>Статус</th>
                </tr>
                <tr>
                  <td className="gradient-text">|GG| Kovboy</td>
                  <td className="center">23</td>
                  <td></td>
                  <td></td>
                  <td className={statusClasses.accepted}>
                    {statusTexts.accepted}
                  </td>
                </tr>
                <tr>
                  <td className="gradient-text">Nickname</td>
                  <td className="center">10</td>
                  <td></td>
                  <td></td>
                  <td className={statusClasses.accepted}>
                    {statusTexts.accepted}
                  </td>
                </tr>
                <tr>
                  <td className="gradient-text">Ник</td>
                  <td className="center">5</td>
                  <td></td>
                  <td></td>
                  <td className={statusClasses.pending}>
                    {statusTexts.pending}
                  </td>
                </tr>
                <tr>
                  <td className="gradient-text">Ник</td>
                  <td className="center">5</td>
                  <td></td>
                  <td></td>
                  <td className={statusClasses.declined}>
                    {statusTexts.declined}
                  </td>
                </tr>
                <tr>
                  <td className="gradient-text">Ник</td>
                  <td className="center">5</td>
                  <td></td>
                  <td></td>
                  <td className={statusClasses.accepted}>
                    {statusTexts.accepted}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default IventsDetails;
