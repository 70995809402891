import React from "react";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../..";
import { paths } from "../../paths";
import Button from "../Button";

const GuildsMenu = () => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const page = location.pathname;

  return (
    <div className="menu">
      <SmallComponentHeading>Меню</SmallComponentHeading>
      <div className="buttons">
        <Link
          to={userStore.choosedServerUrl + paths.Guilds}
          className={
            page === userStore.choosedServerUrl + paths.Guilds ? "active" : ""
          }
        >
          <Button className="extra no-border spiked">ПОИСК КЛАНА</Button>
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.GuildsMy}
          className={
            page === userStore.choosedServerUrl + paths.GuildsMy ? "active" : ""
          }
        >
          <Button className="black no-border spiked">МОЙ КЛАН</Button>
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.GuildsSubs}
          className={
            page === userStore.choosedServerUrl + paths.GuildsSubs
              ? "active"
              : ""
          }
        >
          <Button className="black no-border spiked">ПОДПИСКИ</Button>
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.GuildsCreate}
          className={
            page === userStore.choosedServerUrl + paths.GuildsCreate
              ? "active"
              : ""
          }
        >
          <Button className="black no-border spiked">СОЗДАТЬ ГИЛЬДИЮ</Button>
        </Link>
      </div>
    </div>
  );
};

export default GuildsMenu;
