import React from "react";
import "../../css/mini-heading.css";

const MiniHeading = (props) => {
  return (
    <p {...props} className={"mini-heading " + props.className}>
      {props.children}
    </p>
  );
};

export default MiniHeading;
