import React from "react";
import ComponentContainer from "../../components/ComponentContainer";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import InfoCard from "../../components/Cards/InfoCards/InfoCard";
import image from "../../assets/img/ivent/img_150.png";
import { infoCardTypes, sizeTypes } from "../../utils/consts";
import "../../css/ivents.css";

const Ivents = () => {
  return (
    <div className="ivents-page page">
      <ComponentContainer>
        <div className="ivents-active">
          <SmallComponentHeading>Активные</SmallComponentHeading>
          <InfoCard
            type={infoCardTypes.ivent}
            sizeType={sizeTypes.expanded}
            image={image}
            heading={"Deadly Duo (Саб Трайк + Дьябло)"}
            server={"Megaladon Evrima"}
            dateStart={"02 августа 2023, 18:30"}
            dateEnd={"02 августа 2023, 18:30"}
          />
        </div>
        <div className="ivents-all">
          <SmallComponentHeading>Ивенты</SmallComponentHeading>
          <div className="flex-container ivents-all-container">
            {Array(12)
              .fill(0)
              .map((_, i) => (
                <InfoCard
                  key={i}
                  type={infoCardTypes.ivent}
                  sizeType={sizeTypes.compressed}
                  image={image}
                  heading={"Deadly Duo (Саб Трайк + Дьябло)"}
                  date={"02 августа 2023, 18:30"}
                />
              ))}
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default Ivents;
