import React from "react";
import NavigationLinks from "./NavigationLinks";
import qiwi from "../assets/img/payment/qiwi 1.png";
import mc from "../assets/img/payment/mc 1.png";
import mir from "../assets/img/payment/mir 1.png";
import sbp from "../assets/img/payment/sbp 1.png";
import visa from "../assets/img/payment/visa 1.png";

const Footer = () => {
  return (
    <footer className="center">
      <div>
        <p className="upper-case">Принимаем к оплате</p>
        <div className="payments">
          <img src={qiwi} alt="qiwi" />
          <img src={sbp} alt="sbp" />
          <img src={mir} alt="mir" />
          <img src={mc} alt="mc" />
          <img src={visa} alt="visa" />
        </div>
        <NavigationLinks up={true} />
      </div>
    </footer>
  );
};

export default Footer;
