import React from "react";
import "../../css/sub-heading.css";

const SubHeading = (props) => {
  return (
    <p
      {...props}
      className={`sub-heading ${props.className ? props.className : ""}`}
    >
      {props.children}
    </p>
  );
};

export default SubHeading;
