import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../..";
import userImg from "../../../assets/img/avatar/user1.png";
import dinosaurImg from "../../../assets/img/dinosaur/Изображение.png";
import { ReactComponent as SteamSvg } from "../../../assets/svg/steam.svg";
import A from "../../../components/A";
import Button from "../../../components/Button";
import ComponentContainer from "../../../components/ComponentContainer";
import DinosaurCard from "../../../components/DinosaurCard";
import ComponentHeading from "../../../components/Headings/ComponentHeading";
import HelperHeading from "../../../components/Headings/HelperHeading";
import SmallComponentHeading from "../../../components/Headings/SmallComponentHeading";
import NumerableList from "../../../components/NumerableList";
import "../../../css/media-leaderboards.css";
import "../../../css/user-leaderboard.css";
import { paths } from "../../../paths";
import { dinosaurSizes, dinosaurTypes } from "../../../utils/consts";

const DinosaursLeaderboard = () => {
  const { userStore } = useContext(Context);
  const steamProfile = "https://steamcommunity.com/profiles/76561198359008960";

  return (
    <div className="dinosaurs-leaderboard-page leaderboard-page">
      <ComponentContainer>
        <ComponentHeading>TOP 10</ComponentHeading>
        <div className="center">
          <Link to={userStore.choosedServerUrl + paths.UsersLeaderboard}>
            <Button className="extra no-background">
              К рейтингу пользователей
            </Button>
          </Link>
        </div>
        <HelperHeading>Особенные динозавры</HelperHeading>
        <div className="leaderboard">
          <NumerableList noMarker={true} isTop={true}>
            <div className="list-head">
              <div>Место</div>
              <div>Динозавр</div>
              <div>Владелец</div>
            </div>
            <ol className="list-body">
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
              <li>
                <DinosaurCard
                  tier={3}
                  type={dinosaurTypes.herbivorous}
                  image={dinosaurImg}
                  name={"Шантунгозавр"}
                  size={dinosaurSizes.normal}
                />

                <div className="info">
                  <p className="dinosaur-name">Шантунгозавр</p>
                  <SmallComponentHeading>
                    Dryosaurus altus
                  </SmallComponentHeading>
                  <p className="source">
                    <b>Источник:</b> МегаШоп
                  </p>
                  <p className="age">
                    <b>Возраст:</b> 35 дней
                  </p>
                  <p className="features">
                    <b>Особенности:</b> Может преобразовывать ОА в осколки
                  </p>
                </div>
                <div className="points">
                  <span>12056 ОА</span>
                </div>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="user-name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
              </li>
            </ol>
          </NumerableList>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default DinosaursLeaderboard;
