import React from "react";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../..";
import { paths } from "../../paths";
import Button from "../Button";

const AdminPanelMenu = () => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const page = location.pathname;

  return (
    <div className="menu">
      <SmallComponentHeading>Статусы</SmallComponentHeading>
      <div className="buttons">
        <Link
          to={userStore.choosedServerUrl + paths.AdminPanel}
          className={
            page === userStore.choosedServerUrl + paths.AdminPanel
              ? "active"
              : ""
          }
        >
          <Button className="extra no-border spiked">АКТИВНЫЕ</Button>
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.AdminPanel}
          className={
            page === userStore.choosedServerUrl + paths.AdminPanel
              ? "active"
              : ""
          }
        >
          <Button className="black no-border spiked">ЗАКРЫТЫЕ</Button>
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.AdminPanel}
          className={
            page === userStore.choosedServerUrl + paths.AdminPanel
              ? "active"
              : ""
          }
        >
          <Button className="black no-border spiked">ОТКЛОНЕННЫЕ</Button>
        </Link>
      </div>
    </div>
  );
};

export default AdminPanelMenu;
