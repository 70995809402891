export const wpImagePath = ""; // "/wp-content/reactpress/apps/the-isle/build/";

export const steamProfile =
  "https://steamcommunity.com/profiles/76561198359008960";

export const serverNames = {
  Evrima: "Evrima",
  Legacy: "Legacy",
};

export const serverUrls = {
  evrima: "/evrima",
  legacy: "/legacy",
};

export const dinosaurTypes = {
  herbivorous: "herbivorous",
  predator: "predator",
};

export const dinosaurSizes = {
  smallest: "smallest",
  small: "small",
  normal: "normal",
  big: "big",
  largest: "largest",
};

export const dinosaurTierClassNames = {
  1: "success",
  2: "accent",
  3: "sub-accent",
  4: "dangerous",
};

export const megaShopTypes = {
  herbivorous: "herbivorous",
  predator: "predator",
  cases: "cases",
  busters: "busters",
  eggs: "eggs",
};

export const responsiveSizes = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  xxl: "xxl",
};

export const pageNames = {
  Home: "MEGALODON SURVIVAL",
  Rules: "Правила",
  MegaProfile: "МегаПрофиль",
  MegaShop: "МегаШоп",
  UsersLeaderboard: "Рейтинг игроков",
  DinosaursLeaderboard: "Рейтинг динозавров",
  Profile: "МегаПрофиль",
  Ivents: "Ивенты",
  Guilds: "Гильдии",
  MakeGuild: "Создание Гильдии",
  Achievements: "Достижения",
  TransitionPage: "MEGALODON SURVIVAL",
  Administration: "/administration",
};

export const pageAdditions = {
  Home: "ДОБРО ПОЖАЛОВАТЬ НА",
  TransitionPage: "ДОБРО ПОЖАЛОВАТЬ НА",
};

export const infoCardTypes = {
  guild: "guild",
  ivent: "ivent",
};

export const guildLevelTexts = {
  5: "Эксперт I",
  7: "Эксперт I",
  10: "Эксперт III",
};

export const guildLevelThemes = {
  5: "accent",
  7: "extra",
  10: "dangerous",
};

export const sizeTypes = {
  compressed: "compressed",
  expanded: "expanded",
};

export const statusClasses = {
  pending: "pending gradient-text",
  declined: "declined gradient-text dangerous",
  accepted: "accepted gradient-text success",
};

export const statusTexts = {
  pending: "ожидает",
  declined: "отказано",
  accepted: "Заявка одобрена",
};

export const attentionCardTypes = {
  adminPanel: "adminPanel",
  offers: "offers",
};

export const attentionCardStatuses = {
  active: "active",
  accepted: "accepted",
  declined: "declined",
  onModeration: "onModeration",
  closed: "closed",
};
