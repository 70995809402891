import React, { useState } from "react";
import DinosaurCard from "../DinosaurCard";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import { dinosaurSizes, dinosaurTypes } from "../../utils/consts";

const Catalog = (props) => {
  // const casesData = props.casesData || [];
  // const dinosaursData = props.dinosaursData || [];
  const data = props.data || [];

  return (
    <div className="catalog">
      <SmallComponentHeading>Каталог</SmallComponentHeading>
      <div className="catalog-content flex-container">
        {data.map((itemData, i) => (
          <DinosaurCard key={i} {...itemData} size={dinosaurSizes.big} />
        ))}
        {/* {casesData.map((data, i) => (
          <DinosaurCard key={i} {...data} size={dinosaurSizes.big} />
        ))}
        {dinosaursData.map((data, i) => (
          <DinosaurCard key={i} {...data} size={dinosaurSizes.big} />
        ))} */}
      </div>
    </div>
  );
};

export default Catalog;
