import React from "react";
import "../css/progress-bar.css";

const ProgressBar = (props) => {
  const max = +props.max || 100;
  const percent = props.value ? (+props.value / max) * 100 : 0;

  return (
    <div
      className={`progress-bar ${props.className || ""}${
        percent === 100 ? "full" : ""
      }`}
    >
      {props.children}
      <div
        className={`progress${props.children ? " padding-0" : " padding-1"}`}
        style={{ width: percent + "%" }}
      >
        {!props.children && percent > 0 && `${props.value}/${max}`}
      </div>
    </div>
  );
};

export default ProgressBar;
