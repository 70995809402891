import React, { useContext } from "react";
import NumerableList from "../../components/NumerableList";
import TransitionLayer from "../../components/TransitionLayer";
import { Context } from "../..";
import { pageNames } from "../../utils/consts";
import "../../css/rules.css";
import ComponentContainer from "../../components/ComponentContainer";

const Rules = () => {
  const { userStore } = useContext(Context);
  userStore.setPageName(pageNames.Rules);

  return (
    <>
      <ComponentContainer>
        <div className="rules-page">
          <NumerableList>
            <ol>
              <li>
                <b>Уважение и честность:</b>В игре требуется вести себя
                адекватно и уважительно по отношению к другим игрокам и
                администрации сервера. Запрещено использование любых форм
                мошенничества, читов, багов или других способов нечестной игры.
                Не пытайтесь получить несправедливое преимущество над другими
                игроками.
              </li>
              <li>
                <b>Запрет на разглашение личной информации:</b>
                Запрещено разглашение личной информации других игроков, включая
                их реальные имена, адреса, телефоны и другие конфиденциальные
                данные. Соблюдайте конфиденциальность и права на приватность
                других игроков.
              </li>
              <li>
                <b>Запрет на хакинг и нарушение безопасности:</b>
                Запрещено попытки взлома сервера, использование софта для
                несанкционированного доступа или любые другие действия,
                нарушающие безопасность сервера и других игроков.
              </li>
              <li>
                <b>Запрет на спам и рекламу:</b>
                Запрещено размещение спама, нежелательной рекламы или ссылок на
                сторонние ресурсы без разрешения администрации сервера.
              </li>
              <li>
                <b>Правила использования контента:</b>
                Соблюдайте авторские права и ограничения на использование
                контента, предоставленного игрой. Не пытайтесь распространять
                или использовать нелицензионный контент.
              </li>
              <li>
                <b>Сотрудничество с администрацией:</b>
                Сообщайте обнаруженные баги, проблемы или нарушения правил
                администрации сервера. Сотрудничайте с администрацией, чтобы
                создать комфортную и безопасную игровую среду.
              </li>
              <li>
                <b>Наказания за нарушения:</b>
                Администрация сервера имеет право применять наказания, такие как
                временные или постоянные блокировки аккаунтов, за нарушения
                вышеуказанных правил. Все игроки должны соблюдать и подчиняться
                этим правилам.
              </li>
            </ol>
          </NumerableList>
        </div>
      </ComponentContainer>
    </>
  );
};

export default Rules;
