import UserMenu from "../../components/UserMenu/UserMenu";
import ComponentContainer from "../../components/ComponentContainer";
import "../../css/guilds.css";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import HelperHeading from "../../components/Headings/HelperHeading";
import MiniHeading from "../../components/Headings/MiniHeading";
import SubHeading from "../../components/Headings/SubHeading";
import ComponentHeading from "../../components/Headings/ComponentHeading";
import Button from "../../components/Button";
import img1 from "../../assets/img/guilds/Фон.png";
import img2 from "../../assets/img/guilds/Фон (1).png";
import img3 from "../../assets/img/guilds/Фон (2).png";
import ShowMore from "../../components/ShowMore";
import InfoCard from "../../components/Cards/InfoCards/InfoCard";
import { infoCardTypes } from "../../utils/consts";

const Guilds = () => {
  async function showMore() {
    // show more
  }
  return (
    <div className="guild-page page">
      <ComponentContainer className="navigation">
        <UserMenu guilds={true} />
        <div className="guilds">
          <SmallComponentHeading>
            Гильдии сервера{" "}
            <span className="gradient-text font-size-2-25">24</span>
          </SmallComponentHeading>
          <div className="guilds-container info-list">
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <InfoCard
                  type={infoCardTypes.guild}
                  key={i}
                  image={img1}
                  heading={"Šoprano"}
                  leader={"{Š} W Ø_ø L F"}
                  members={33}
                  points={82373}
                  teeth={78955}
                  oa={259332}
                  level={10}
                />
              ))}
          </div>
          <ShowMore onClick={showMore} />
        </div>
      </ComponentContainer>
    </div>
  );
};

export default Guilds;
