import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Context } from "..";
import { pageAdditions, pageNames } from "../utils/consts";

const TransitionPage = () => {
  const { userStore } = useContext(Context);
  userStore.setPageName(pageNames.TransitionPage, pageAdditions.TransitionPage);

  //   const root = document.querySelector("#root");
  //   root.classList.add("transition");
  // в App.jsx уже есть TransitionLayer
  return;
};
export default observer(TransitionPage);
