import React, { useContext } from "react";
import { Link } from "react-router-dom";
import userImg from "../../../assets/img/avatar/user1.png";
import { ReactComponent as SteamSvg } from "../../../assets/svg/steam.svg";
import A from "../../../components/A";
import Button from "../../../components/Button";
import ComponentContainer from "../../../components/ComponentContainer";
import ComponentHeading from "../../../components/Headings/ComponentHeading";
import SmallComponentHeading from "../../../components/Headings/SmallComponentHeading";
import NumerableList from "../../../components/NumerableList";
import "../../../css/user-leaderboard.css";
import { Context } from "../../../index";
import { paths } from "../../../paths";

const UsersLeaderboard = () => {
  const { userStore } = useContext(Context);
  const steamProfile = "https://steamcommunity.com/profiles/76561198359008960";
  return (
    <div className="users-leaderboard-page leaderboard-page">
      <ComponentContainer>
        <ComponentHeading>TOP 100</ComponentHeading>
        <div className="center">
          <Link to={userStore.choosedServerUrl + paths.DinosaursLeaderboard}>
            <Button className="extra no-background">
              К рейтингу динозавров
            </Button>
          </Link>
        </div>
        <div className="buttons">
          <Button className="extra no-background">За последние 3 месяца</Button>
          <Button className="extra no-background">За все время</Button>
          <Button className="extra no-background">По уровню</Button>
        </div>
        <div className="filter linear">
          <SmallComponentHeading>Сортировать</SmallComponentHeading>
          <div className="section">
            <div className="section-title">ТОП</div>
            <ul className="no-marker">
              <li>
                <input type="checkbox" id="по кол-ву Убийств" />
                <label for="по кол-ву Убийств">по кол-ву Убийств</label>
              </li>
              <li>
                <input type="checkbox" id="по кол-ву Слотов" />
                <label for="по кол-ву Слотов">по кол-ву Слотов</label>
              </li>
              <li>
                <input type="checkbox" id="по Часам онлайна" />
                <label for="по Часам онлайна">по Часам онлайна</label>
              </li>
              <li>
                <input type="checkbox" id="по Очкам Активности" />
                <label for="по Очкам Активности">по Очкам Активности</label>
              </li>
              <li>
                <input type="checkbox" id="по Уровню" />
                <label for="по Уровню">по Уровню</label>
              </li>
            </ul>
          </div>
          <div className="section">
            <div className="section-title">СЕРВЕР</div>
            <ul className="no-marker">
              <li>
                <input type="checkbox" id="Evrima" />
                <label for="Evrima">Evrima</label>
              </li>
              <li>
                <input type="checkbox" id="Survival" />
                <label for="Survival">Survival</label>
              </li>
              <li>
                <input type="checkbox" id="Legacy" />
                <label for="Legacy">Legacy</label>
              </li>
            </ul>
          </div>
          <Button className="accent no-border">Найти</Button>
        </div>
        <div className="leaderboard">
          <NumerableList noMarker={true} isTop={true}>
            <div className="list-head">
              <div>Место</div>
              <div>Игрок</div>
              <div>Клан</div>
              <div>Очки</div>
            </div>
            <ol className="list-body">
              <li>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
                <div className="clan">
                  <span>Reptile Union</span>
                </div>
                <div className="points">
                  <span>505</span>
                </div>
              </li>
              <li>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
                <div className="clan">
                  <span>Reptile Union</span>
                </div>
                <div className="points">
                  <span>505</span>
                </div>
              </li>
              <li>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
                <div className="clan">
                  <span>Reptile Union</span>
                </div>
                <div className="points">
                  <span>505</span>
                </div>
              </li>
              <hr />
              <li>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
                <div className="clan">
                  <span>Reptile Union</span>
                </div>
                <div className="points">
                  <span>505</span>
                </div>
              </li>
              <li>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
                <div className="clan">
                  <span>Reptile Union</span>
                </div>
                <div className="points">
                  <span>505</span>
                </div>
              </li>
              <li>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
                <div className="clan">
                  <span>Reptile Union</span>
                </div>
                <div className="points">
                  <span>505</span>
                </div>
              </li>
              <li>
                <div className="avatar">
                  <img src={userImg} alt="" />
                </div>
                <div className="name">
                  <SmallComponentHeading>Dizi</SmallComponentHeading>
                  <A href={steamProfile} className="steam-link">
                    <SteamSvg /> Steam
                  </A>
                </div>
                <div className="clan">
                  <span>Reptile Union</span>
                </div>
                <div className="points">
                  <span>505</span>
                </div>
              </li>
            </ol>
          </NumerableList>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default UsersLeaderboard;
