import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { independentPaths, paths } from "../paths";
import Dropdown from "./Dropdown";
import { Context } from "..";
import { serverUrls } from "../utils/consts";

const NavigationLinks = (props) => {
  const { userStore } = useContext(Context);
  const location = useLocation();
  const page = location.pathname;
  const up = props.up !== undefined;

  return (
    <Dropdown value={"МЕНЮ"} up={up} className="navigation-links resp-lg">
      <Link
        to={userStore.choosedServerUrl + paths.Home}
        className={
          page === userStore.choosedServerUrl + paths.Home ? "active" : ""
        }
      >
        ГЛАВНАЯ
      </Link>
      <Link
        to={
          userStore.choosedServerUrl === serverUrls.legacy
            ? independentPaths.Rules
            : independentPaths.RulesEvrima
        }
        className={
          page === independentPaths.Rules ||
          page === independentPaths.RulesEvrima
            ? "active"
            : ""
        }
      >
        ПРАВИЛА
      </Link>
      <Link
        to={userStore.choosedServerUrl + paths.MegaShop}
        className={
          page === userStore.choosedServerUrl + paths.MegaShop ? "active" : ""
        }
      >
        МЕГАШОП
      </Link>
      <Dropdown
        value="МЕГАПРОФИЛЬ"
        up={up}
        className={
          page === userStore.choosedServerUrl + paths.MegaProfile
            ? "active"
            : ""
        }
      >
        <Link
          to={userStore.choosedServerUrl + paths.MegaProfile}
          className={
            page === userStore.choosedServerUrl + paths.MegaProfile
              ? "active"
              : ""
          }
        >
          МОЙ МЕГАПРОФИЛЬ
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.MyDinosaurs}
          className={
            page === userStore.choosedServerUrl + paths.MyDinosaurs
              ? "active"
              : ""
          }
        >
          МОИ ДИНОЗАВРЫ
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.MyStorage}
          className={
            page === userStore.choosedServerUrl + paths.MyStorage
              ? "active"
              : ""
          }
        >
          ХРАНИЛИЩЕ
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.MegaBank}
          className={
            page === userStore.choosedServerUrl + paths.MegaBank ? "active" : ""
          }
        >
          МЕГАБАНК
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.MyStatistics}
          className={
            page === userStore.choosedServerUrl + paths.MyStatistics
              ? "active"
              : ""
          }
        >
          МОЯ АКТИВНОСТЬ
        </Link>
      </Dropdown>
      <Dropdown value="СЕРВЕР" up={up}>
        <Link
          to={userStore.choosedServerUrl + paths.UsersLeaderboard}
          className={
            page === userStore.choosedServerUrl + paths.UsersLeaderboard
              ? "active"
              : ""
          }
        >
          Рейтинг игроков
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.DinosaursLeaderboard}
          className={
            page === userStore.choosedServerUrl + paths.DinosaursLeaderboard
              ? "active"
              : ""
          }
        >
          Рейтинг динозавров
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.Achievements}
          className={
            page === userStore.choosedServerUrl + paths.Achievements
              ? "active"
              : ""
          }
        >
          Достижения
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.BattlePass}
          className={
            page === userStore.choosedServerUrl + paths.BattlePass
              ? "active"
              : ""
          }
        >
          Боевой пропуск
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.FortuneWheel}
          className={
            page === userStore.choosedServerUrl + paths.FortuneWheel
              ? "active"
              : ""
          }
        >
          Колесо удачи
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.RoulettePage}
          className={
            page === userStore.choosedServerUrl + paths.RoulettePage
              ? "active"
              : ""
          }
        >
          Рулетка
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.Administration}
          className={
            page === userStore.choosedServerUrl + paths.Administration
              ? "active"
              : ""
          }
        >
          Администрация
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.Offers}
          className={
            page === userStore.choosedServerUrl + paths.Offers ? "active" : ""
          }
        >
          Предложения
        </Link>
        <Link
          to={userStore.choosedServerUrl + paths.AdminPanel}
          className={
            page === userStore.choosedServerUrl + paths.AdminPanel
              ? "active"
              : ""
          }
        >
          Админ панель
        </Link>
      </Dropdown>
      <Link
        to={userStore.choosedServerUrl + paths.Ivents}
        className={
          page === userStore.choosedServerUrl + paths.Ivents ? "active" : ""
        }
      >
        ИВЕНТЫ
      </Link>
      <Link
        to={userStore.choosedServerUrl + paths.Guilds}
        className={
          page === userStore.choosedServerUrl + paths.Guilds ? "active" : ""
        }
      >
        ГИЛЬДИИ
      </Link>
    </Dropdown>
  );
};

export default NavigationLinks;
