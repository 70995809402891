import React from "react";
import ComponentContainer from "../../../components/ComponentContainer";
import SubHeading from "../../../components/Headings/SubHeading";
import DinosaurCard from "../../../components/DinosaurCard";
import { dinosaurSizes, dinosaurTypes } from "../../../utils/consts";
import Roulette from "../../../components/Rouletts/Roulette";
import "../../../css/roulette-page.css";
import img1 from "../../../assets/img/dinosaur/Изображение.png";
import img2 from "../../../assets/img/dinosaur/Изображение1.png";
import img3 from "../../../assets/img/dinosaur/Изображение2.png";
import img4 from "../../../assets/img/dinosaur/Изображение3.png";

const RoulettePage = () => {
  return (
    <div className="roulette-page page">
      <ComponentContainer>
        <div className="roulette-container">
          <div className="roulette-container-body">
            <div className="roulette-page-head">
              <span className="bold text-outlined font-size-3-25">
                КОЛЕСО УДАЧИ
              </span>
              <div className="user-points">
                <p>
                  <span>У ВАС: </span>
                  <span className="text-accent">3.000 OA</span>
                </p>
                <p>
                  <span>1 ПРОКРУТ = 100 OA</span>
                </p>
              </div>
            </div>
            <div className="roulette-page-body">
              <Roulette
                item={DinosaurCard}
                data={[
                  {
                    name: "НАИМЕНОВАНИЕ",
                    tier: 4,
                    type: dinosaurTypes.predator,
                    image: img1,
                    size: dinosaurSizes.normal,
                    chance: 10,
                  },
                  {
                    name: "НАИМЕНОВАНИЕ",
                    tier: 3,
                    type: dinosaurTypes.herbivorous,
                    image: img2,
                    size: dinosaurSizes.normal,
                    chance: 25,
                  },
                  {
                    name: "НАИМЕНОВАНИЕ",
                    tier: 2,
                    type: dinosaurTypes.predator,
                    image: img3,
                    size: dinosaurSizes.normal,
                    chance: 60,
                  },
                  {
                    name: "НАИМЕНОВАНИЕ",
                    tier: 1,
                    type: dinosaurTypes.herbivorous,
                    image: img4,
                    size: dinosaurSizes.normal,
                    chance: 100,
                  },
                ]}
              />
              <div className="flex-container">
                {Array(20)
                  .fill(0)
                  .map((_, i) => (
                    <DinosaurCard
                      tier={2}
                      type={dinosaurTypes.predator}
                      image={img2}
                      name={"НАИМЕНОВАНИЕ"}
                      size={dinosaurSizes.small}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default RoulettePage;
