import { Link } from "react-router-dom";
import ComponentContainer from "../../components/ComponentContainer";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import "../../css/administration.css";

const Administration = () => {
  return (
    <div className="administration-page">
      <SmallComponentHeading>Администрация проекта</SmallComponentHeading>
      <ComponentContainer>
        <table className="administration-table">
          <thead>
            <td>Статус</td>
            <td>Никнейм</td>
            <td>Должность</td>
            <td>На сервере</td>
          </thead>
          <tbody>
            <tr className="admin-info">
              <td className="status">
                <div />
                <label className="hidden">Активен</label>
              </td>
              <td className="nickname">Никнейм</td>
              <td className="job-title">Администратор</td>
              <td className="is-on-server">Нет</td>
            </tr>
            <tr className="admin-info">
              <td className="status not">
                <div />
                <label className="hidden">Не активен</label>
              </td>
              <td className="nickname">Никнейм</td>
              <td className="job-title">Администратор</td>
              <td className="is-on-server">Да</td>
            </tr>
            <tr className="admin-info">
              <td className="status not">
                <div />
                <label className="hidden">Не активен</label>
              </td>
              <td className="nickname">Никнейм</td>
              <td className="job-title">Администратор</td>
              <td className="is-on-server">Нет</td>
            </tr>
            <tr className="admin-info">
              <td className="status">
                <div />
                <label className="hidden">Активен</label>
              </td>
              <td className="nickname">Никнейм</td>
              <td className="job-title">Администратор</td>
              <td className="is-on-server">Да</td>
            </tr>
          </tbody>
        </table>
        <SmallComponentHeading>
          Как стать администратором сервера?
        </SmallComponentHeading>
        <p>
          Пожалуйста ознакомьтесь с информацией на{" "}
          <Link to={"#"}>этой странице</Link> и оставьте заявку по форме.
        </p>
      </ComponentContainer>
    </div>
  );
};

export default Administration;
