import React from "react";
import "../../css/component-heading.css";

const ComponentHeading = (props) => {
  return (
    <p
      {...props}
      className={`main-heading ${props.className ? props.className : ""}`}
    >
      {props.children}
    </p>
  );
};

export default ComponentHeading;
