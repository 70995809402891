import React from "react";
import ComponentContainer from "../../components/ComponentContainer";
import UserMenu from "../../components/UserMenu/UserMenu";

const GuildsSubs = () => {
  return (
    <div className="guild-page page">
      <ComponentContainer className="navigation">
        <UserMenu guilds={true} />
      </ComponentContainer>
    </div>
  );
};

export default GuildsSubs;
