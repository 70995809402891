import React, { useContext } from "react";
import NumerableList from "../../components/NumerableList";
import TransitionLayer from "../../components/TransitionLayer";
import { Context } from "../..";
import { pageNames } from "../../utils/consts";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
// import "../../css/rules-evrima.css";

const RulesEvrima = () => {
  const { userStore } = useContext(Context);
  userStore.setPageName(pageNames.Rules);

  return (
    <>
      <div className="rules-evrima-page">
        <SmallComponentHeading>Правила серверов Evrima</SmallComponentHeading>
        <NumerableList>
          <ol className="nested-list">
            <li>
              <div className="head gradient-text font-size-1-75 semi-bold">
                Правила серверов Evrima
              </div>
              <ol>
                <li>
                  Cм. правила Discord - общие положения, размещение ссылок, ники
                  и аватарки
                </li>
              </ol>
            </li>
            <li>
              <div className="head gradient-text font-size-1-75 semi-bold">
                Общие игровые правила Evrima
              </div>
              <ol>
                <li>
                  Запрещены читы и любые программы дающие преимущество над
                  другими игроками
                </li>
                <li>
                  Запрещено убивать администратора вне игровых ситуаций,
                  например при освобождении из текстур
                </li>
                <li>
                  Запрещено убивать членов группы и стада. После выхода из
                  группы или из стада, одного из её участников запрещено убивать
                  и преследовать в течение{" "}
                  <span className="gradient-text">15 минут</span>
                </li>
                <li>
                  Травоядным запрещено сближаться первыми и преследовать
                  хищников
                </li>
                <li>
                  <span className="gradient-text success">Травоядные</span>{" "}
                  (+Gallimimus) не имеют права нападать первыми на{" "}
                  <span className="gradient-text dangerous">хищников</span>,
                  исключением является быстрое сближение плотоядного к вам на
                  спринте (ускорение) на линию атаки или агрессия (крик на 3, у
                  церы + пкм), а также если есть угроза потомству (яйцам). Все
                  условия действуют, если{" "}
                  <span className="gradient-text dangerous">хищник</span>{" "}
                  находится в непосредственной близости (на расстоянии примерно
                  3-4 adult Deinosuchus от{" "}
                  <span className="gradient-text success">травоядного</span>)
                </li>
                <li>
                  <span className="gradient-text success">Травоядные</span>{" "}
                  (+Gallimimus) могут нападать на других{" "}
                  <span className="gradient-text success">травоядных</span>
                  (+Gallimimus) только если обе стороны "прожали" агрессивный
                  крик на цифру 3, тем самым подтвердив и приняв бой. В случае
                  если 1 из сторон не реагирует на крик в течении{" "}
                  <span className="gradient-text">2 минут</span> и при этом не
                  отходит (запрещено преследовать{" "}
                  <span className="gradient-text success">травоядного</span>,
                  если тот начал уходить), можно начинать атаку
                </li>
                <li>
                  <span className="gradient-text success">Травоядным</span>{" "}
                  (+Gallimimus) запрещено рушить гнезда других
                  <span className="gradient-text success">травоядных</span>{" "}
                  (+Gallimimus)
                </li>
                <li>
                  Запрещено межвидовое сотрудничество среди{" "}
                  <span className="gradient-text dangerous">хищников</span>{" "}
                  (+Beipiaosaurus) и{" "}
                  <span className="gradient-text success">травоядных</span>{" "}
                  (+Gallimimus), а также между ними, кроме пунктов указанных в
                  разделах 3, 4, 5
                </li>
                <li>
                  <span className="gradient-text success">Травоядные</span>{" "}
                  (+Gallimimus) могут собираться в стадо согласно разделу 5.
                </li>
              </ol>
            </li>
            <li>
              <div className="head gradient-text font-size-1-75 semi-bold">
                Лимиты хищников (+Beipiaosaurus)
              </div>
              <ol>
                <li className="no-num num-warning">
                  В игре <b>4 стадии:</b> от самого маленького - Hatch, Juvie и
                  до взрослых - Sub, Adult, в лимитах указаны Sub и Adult
                  стадии. Hatch, Juvie - стадии Безлимит
                </li>
                <li>
                  <p>
                    <span className="gradient-text">2 шт.</span> - Deinosuchus
                  </p>
                  <p>
                    <span className="gradient-text">4 шт.</span> - Ceratosaurus
                  </p>
                  <p>
                    <span className="gradient-text">3 шт.</span> - Carnotaurus
                  </p>
                  <p>
                    <span className="gradient-text">8 шт.</span> - Pteranodon
                    (разрешен межвид со всеми динозаврами)
                  </p>
                  <p>
                    <span className="gradient-text">8 шт.</span> - Omniraptor
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span> -
                    Beipiaosaurus (разрешен межвид с Deinosuchus)
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span> - Troodon
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <div className="head gradient-text font-size-1-75 semi-bold">
                Лимиты травоядных НЕ в стаде (без межвида) (+Gallimimus)
              </div>
              <ol>
                <li className="no-num num-warning">
                  В игре 4 стадии: от самого маленького - Hatch, Juvie и до
                  взрослых - Sub, Adult, в лимитах указаны Sub и Adult стадии.
                  Hatch, Juvie - стадии Безлимит
                </li>
                <li>
                  <p>
                    <span className="gradient-text">2 шт.</span> - Stegosaurus
                  </p>
                  <p>
                    <span className="gradient-text">8 шт.</span> - Tenontosaurus
                  </p>
                  <p>
                    <span className="gradient-text">8 шт.</span> -
                    Pachycephalosaurus
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span> - Dryosaurus
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span> - Gallimimus
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span> -
                    Hypsilóphodon
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <div className="head gradient-text font-size-1-75 semi-bold">
                Лимиты травоядных в стаде (межвид) (+Gallimimus)
              </div>
              <ol>
                <li className="no-num num-warning">
                  В игре 4 стадии: от самого маленького - Hatch, Juvie и до
                  взрослых - Sub, Adult, в лимитах указаны Sub и Adult стадии.
                  Hatch, Juvie - стадии Безлимит
                </li>
                <li>
                  <p>
                    <span className="gradient-text">3 тир</span> - Stegosaurus
                  </p>
                  <p>
                    <span className="gradient-text">2 тир</span> -
                    Tenontosaurus, Pachycephalosaurus, Gallimimus
                  </p>
                  <p>
                    <span className="gradient-text">1 тир </span>- Dryosaurus,
                    Hypsilóphodon.
                  </p>
                </li>
                <li>
                  <p>По лимитам тиров</p>
                  <p>
                    <span className="gradient-text">1 шт.</span>- 3 тир
                  </p>
                  <p>
                    <span className="gradient-text">6 шт.</span> - 2 тир
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span> - 1 тир
                  </p>
                </li>
                <li>
                  <p>По лимитам видов в стаде</p>
                  <p>
                    <span className="gradient-text">1 шт.</span> - Stegosaurus
                    (3 тир)
                  </p>
                  <p>
                    <span className="gradient-text">6 шт.</span> - Tenontosaurus
                    (2 тир)
                  </p>
                  <p>
                    <span className="gradient-text">6 шт.</span> - Gallimimus (2
                    тир)
                  </p>
                  <p>
                    <span className="gradient-text">6 шт.</span> -
                    Pachycephalosaurus (2 тир)
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span> - Dryosaurus
                    (1 тир)
                  </p>
                  <p>
                    <span className="gradient-text">10 шт.</span>- Hypsilóphodon
                    (1 тир)
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <div className="head gradient-text font-size-1-75 semi-bold">
                Обязательно к прочтению
              </div>
              <ol>
                <li className="no-num num-warning">
                  При нарушении правила чата админ выдает предупреждение - кик -
                  бан, в то время как при серьёзном, админ имеет право сразу
                  кикнуть или забанить игрока
                </li>
                <li className="no-num num-warning">
                  При игровом нарушении, если возможны потери одной из сторон,
                  админ вправе сразу пресечь его, выдав кик, при игнорировании -
                  бан
                </li>
                <li className="no-num num-warning">
                  При наличии матов, нецензурной лексики, 18+ в нике,
                  администратор выдает кик с просьбой о смене ника. При
                  игнорировании следует бан
                </li>
                <li className="no-num num-warning">
                  Админам запрещено вмешиваться в игровой процесс игроков,
                  телепортировать (искл. освобождение из текстур), выдавать
                  рост, кормить, восстанавливать здоровье, выдавать
                  местонахождение игрока, использовать любые админ преимущества.
                </li>
                <li className="no-num num-warning">
                  Админам запрещено вмешиваться в игровой процесс игроков,
                  телепортировать (искл. освобождение из текстур), выдавать
                  рост, кормить, восстанавливать здоровье, выдавать
                  местонахождение игрока, использовать любые админ преимущества.
                </li>
                <li className="no-num num-warning">
                  Администрация не несет ответственности за потерю динозавра в
                  следствии применения санкций за нарушение правил.
                </li>
              </ol>
            </li>
          </ol>
        </NumerableList>
      </div>
    </>
  );
};

export default RulesEvrima;
