import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "..";
import { ReactComponent as Arrow } from "../assets/svg/arrow-circle-down.svg";
import "../css/transition-layer.css";
import { independentPaths } from "../paths";
import { serverUrls } from "../utils/consts";
import { toggleActive } from "../utils/helpers";
import Button from "./Button";

const TransitionLayer = (props) => {
  const { userStore } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  function chooseEvrima(e) {
    if (location.pathname === independentPaths.TransitionPage) {
      toggleActive(e);
    }

    userStore.setChoosedServerUrl(serverUrls.evrima);

    let path = location.pathname.replace(serverUrls.legacy, serverUrls.evrima);
    if (path === "/") {
      path = serverUrls.evrima;
    }
    navigate(path);
  }
  function chooseLegacy(e) {
    if (location.pathname === independentPaths.TransitionPage) {
      toggleActive(e);
    }

    userStore.setChoosedServerUrl(serverUrls.legacy);

    let path = location.pathname.replace(serverUrls.evrima, serverUrls.legacy);
    if (path === "/") {
      path = serverUrls.legacy;
    }
    navigate(path);
  }

  return (
    <div
      className={`transition-layer active-handler${
        location.pathname === independentPaths.TransitionPage ? " page" : ""
      }`}
    >
      <div className="title">
        {userStore.pageName.addition && (
          <p className="addition-heading">{userStore.pageName.addition}</p>
        )}
        <h1 className="main-heading">
          {userStore.pageName.name || "MEGALODON SURVIVAL"}
        </h1>
      </div>
      <div className="servers">
        <Button className="no-border no-background" onClick={chooseEvrima}>
          <Arrow />
          EVRIMA
        </Button>
        <Button className="no-border no-background" onClick={chooseLegacy}>
          <Arrow />
          LEGACY
        </Button>
      </div>
    </div>
  );
};

export default observer(TransitionLayer);
