import React from "react";
import "../css/dropdown.css";
import { ReactComponent as ArrowDown } from "../assets/svg/arrow_down.svg";
import { toggleActive } from "../utils/helpers";

const Dropdown = (props) => {
  return (
    <div
      className={`dropdown active-handler ${
        props.className ? props.className : ""
      } ${props.up ? " up" : ""}`}
    >
      <button type="button" className="dropdown-button" onClick={toggleActive}>
        {props.value}
        <ArrowDown />
      </button>
      <div className="dropdown-content">{props.children}</div>
    </div>
  );
};

export default Dropdown;
