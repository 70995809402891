import React, { useState } from "react";
import ComponentContainer from "../../components/ComponentContainer";
import UserMenu from "../../components/UserMenu/UserMenu";
import SmallComponentHeading from "../../components/Headings/SmallComponentHeading";
import { ReactComponent as PeopleSvg } from "../../assets/svg/possibility/people.svg";
import { ReactComponent as SquareSvg } from "../../assets/svg/possibility/grid.svg";
import { ReactComponent as PrecentSvg } from "../../assets/svg/precent.svg";
import { ReactComponent as CalendarSvg } from "../../assets/svg/calendar.svg";
import { ReactComponent as StarSvg } from "../../assets/svg/possibility/star.svg";
import { ReactComponent as ShieldSvg } from "../../assets/svg/possibility/door.svg";
import { ReactComponent as StarsSvg } from "../../assets/svg/possibility/stars.svg";
import { ReactComponent as TimeSvg } from "../../assets/svg/possibility/time.svg";
import { ReactComponent as WalletSvg } from "../../assets/svg/wallet.svg";
import { ReactComponent as DiscordSvg } from "../../assets/svg/discord.svg";
import "../../css/guilds-create.css";
import Button from "../../components/Button";

const GuildsCreate = () => {
  const [guildName, setGuildName] = useState("");
  const [guildPrefix, setGuildPrefix] = useState("");
  const [guildDescription, setGuildDescription] = useState("");
  const [guildSlogan, setGuildSlogan] = useState("");

  return (
    <div className="guild-create-page page">
      <ComponentContainer className="navigation">
        <UserMenu guilds={true} />
        <div className="guild-create">
          <SmallComponentHeading>Возможности гильдии</SmallComponentHeading>
          <div className="guild-create-container">
            <div className="guild-benefits">
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <PeopleSvg width="32.91" height="23.04" />
                </div>
                <div className="guild-benefit-desc">
                  <span>Участие в турнирах</span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <SquareSvg width="28" height="28" />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    +1 дополнительный слот для всех соклановцев, как только
                    гильдия достигнет статуса Бронза.{" "}
                  </span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <PrecentSvg width="26.95" height="28.27" />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    Постоянные скидки в МегаШопе на покупку динозавров. +5% за
                    каждый уровень Гильдии.
                  </span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <CalendarSvg width="28" height="24" />
                </div>
                <div className="guild-benefit-desc">
                  <span>Личный кабинет клана, для его управления.</span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <StarSvg width="27" height="24" />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    Распределение участников клана по ролям, с разными
                    привелегиями.
                  </span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <ShieldSvg width="21" height="26" />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    Гильдийское хранилище, позволяющее помещать динозавров
                    соклановцев, а также передавать любому участнику Гильдии. 5
                    слотов за каждый уровень Гильдии
                  </span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <StarsSvg width="28" height="28" />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    Бесплатная смена скина динозаврам, если выбран именно
                    гильдийский скин.
                  </span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <TimeSvg width="24" height="24" />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    Возможность быстро запрашивать динозавра с казны Гильдии.
                  </span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <WalletSvg width="22.15" height="20.4" />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    Быстрое пополнение казны Гильдии с личного баланса.
                  </span>
                </div>
              </div>
              <div className="guild-benefit">
                <div className="guild-benefit-icon svg-text">
                  <DiscordSvg />
                </div>
                <div className="guild-benefit-desc">
                  <span>
                    Просмотр подписчиков/сторонников Гильдии, не состоящих в
                    нем. Интеграция с вашим Discord-сервером, для дублирования
                    информации активности Гильдии. Личная новостная лента.{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="attention">
              <SmallComponentHeading>Ознакомление</SmallComponentHeading>
              <div className="attention-text gradient-background extra">
                <p>Стоимость создания клана: 500 зуб.</p>
                <p>
                  После подачи заявки на создание гильдии, ее будет
                  рассматривать администрация проекта, которая примет решение
                  одобрять или же отклонять вашу заявку.
                </p>
              </div>
            </div>
            <div className="guild-request">
              <SmallComponentHeading>
                Подача заявки на создание гильдии
              </SmallComponentHeading>
              <p>
                Укажите данные своего будущего клана, заполнив соответствующие
                поля
              </p>
              <div className="input">
                <label for="guild-name">Наименование гильдии: *</label>
                <input
                  type="text"
                  id="guild-name"
                  className="border-extra fullwidth"
                  placeholder="Укажите наименования гильдии"
                  value={guildName}
                  onChange={(e) => setGuildName(e.target.value)}
                />
              </div>
              <div className="input">
                <label for="guild-prefix">Префикс гильдии: *</label>
                <input
                  type="text"
                  id="guild-prefix"
                  className="border-extra fullwidth"
                  placeholder="Укажите префикс гильдии, который будет отображаться  в никах участников"
                  value={guildPrefix}
                  onChange={(e) => setGuildPrefix(e.target.value)}
                />
              </div>
              <div className="input">
                <label for="guild-desc">Описание: *</label>
                <textarea
                  type="text"
                  id="guild-desc"
                  className="border-extra fullwidth"
                  placeholder="Опишите свою гильдию"
                  value={guildDescription}
                  onChange={(e) => setGuildDescription(e.target.value)}
                />
              </div>
              <div className="input">
                <label for="guild-slogan">Слоган (девиз): *</label>
                <input
                  type="text"
                  id="guild-slogan"
                  className="border-extra fullwidth"
                  placeholder="Укажите девиз гильдии"
                  value={guildSlogan}
                  onChange={(e) => setGuildSlogan(e.target.value)}
                />
              </div>
              <p className="caution">
                Нажав кнопку <b>"Отправить"</b>, с вашего личного баланса будет
                списано <b>500 зуб</b>, а также <b>вы станете лидером клана</b>
              </p>
              <Button className="start no-border extra">
                отправить на проверку
              </Button>
            </div>
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default GuildsCreate;
