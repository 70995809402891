import React from "react";
import ComponentContainer from "../../../components/ComponentContainer";
import UserMenu from "../../../components/UserMenu/UserMenu";
import SmallComponentHeading from "../../../components/Headings/SmallComponentHeading";
import { Link } from "react-router-dom";
import { paths } from "../../../paths";
import Vote from "../../../components/Vote";
import "../../../css/offer-details.css";

const OfferDetails = () => {
  return (
    <div className="offer-details-page page">
      <ComponentContainer className="navigation">
        <UserMenu offers={true} />
        <div className="offer-details">
          <SmallComponentHeading className="flex">
            Подарки за топ PVP в сезонах{" "}
            <Link to={paths.Offers} className="font-size-1 gradient-text">
              назад
            </Link>
          </SmallComponentHeading>
          <div className="offer-details-container">
            <p className="data">
              <span>
                <span>Автор: </span>
                <span className="gradient-text extra">licoris⸙</span>
              </span>
              <span>01 июля 2023, 22:14</span>
              <span>
                <span>Категория: </span>
                <span className="gradient-text extra">Сервер</span>
              </span>
            </p>
            <p className="data">
              <span>
                <span>Сервер: </span>
                <span className="gradient-text extra">Megaladon Evrima</span>
              </span>
              <span>
                <span>Условие участия: </span>
                <span>Зарегистрированный пользователь</span>
              </span>
            </p>
            <div className="gradient-background extra attention-text">
              Когда появятся сезоны и сезонные диносы ввести отдельный рейтинг
              PVP за сезон
              <br />
              Всего 2 рейтинга:
              <br />
              1. Постоянный, убийства и очки за все время
              <br />
              2. За определенный сезон
              <br />
              Подарки за топ 3 в сезоне, и именно не за убийства, а за очки,
              чтоб просто так не косили джувов (за мелуих вооюще в теории очки
              можно было бы для сезона убрать, оставив только за сабов и
              адолтов)
            </div>
            <SmallComponentHeading>Проголосовать</SmallComponentHeading>
            <Vote likeCount={140} dislikeCount={12} />
          </div>
        </div>
      </ComponentContainer>
    </div>
  );
};

export default OfferDetails;
